import React from 'react';
import TextField from '@material-ui/core/TextField';
import BasicLabel from '../BasicLabel/BasicLabel';
import './BasicTextArea.scss';

export default class BasicTextArea extends React.Component {
    render() {
        let error = this.props?.value?.length > this.props.limit || (this.props.showMandatory && !this.props?.value) || false;
        let mandatoryWarning = "Please complete this mandatory field";
        return (
            <div className="BasicTextAreaContainer">
                {this.props?.label && 
                    <BasicLabel 
                        text={this.props?.label || ""} 
                        type={"text"}
                        isMandatory={this.props?.isMandatory || false} 
                        typedCharCount={this.props?.typedCharCount}
                        charLimit={this.props?.limit}
                        showCharCount={this.props?.showCharCount}
                    />}
                {this.props.showAsLabel ? <div className="showAsLabel">{this.props.value || ""} </div> :
                    <TextField id={this.props?.id}
                        variant="outlined"
                        multiline
                        rows={this.props.rows || 3}
                        autoComplete='off'
                        value={this.props?.value || ""}
                        cols='50'
                        onChange={this.props?.onChange}
                        disabled={this.props?.disabled || false}
                        placeholder={this.props?.placeholder || ""}
                        className={this.props?.dividers ? "textAreaDividers" : ""} />}
                {error && <div className="errorText">{((this.props.showMandatory && !this.props?.value) ? mandatoryWarning : this.props.limitWarning) || ""}</div>}
            </div>
        );
    }
}
