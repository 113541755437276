import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from "@material-ui/core/styles";
import BasicTextField from '../../../SharedComponents/BasicTextField/BasicTextField';
import ListField from '../../../SharedComponents/ListField/ListField';
import BasicLabel from '../../../SharedComponents/BasicLabel/BasicLabel';
import BasicCheckBox from '../../../SharedComponents/BasicCheckbox/BasicCheckbox';
import AutocompleteAdd from '../../../SharedComponents/AutocompleteAdd/AutocompleteAdd';
import SelectField from '../../../SharedComponents/SelectField/SelectField';
import SearchSelectField from '../../../SharedComponents/SearchSelectField';
import SearchFieldWithAddValue from '../../../SharedComponents/SearchFieldWithAddValue';
import MessageModal from '../../../SharedComponents/MessageModal';
import NotificationComponent from '../../../../Common/NotificationComponent/NotificationComponent';
import { validateCharctersSpaceOnly } from '../../../../Common/Helper';
import messages from '../../../../Common/Messages.json';
import { limit, talentInitialDetails } from './Config';
import ScripterService from '../../../../services/service';
import * as Constants from '../../../../constants/constants';
import { withUserContext } from '../../../../contexts/UserContext';
import SubAttachedFiles from './SubAttachedFiles';
import DateField from "../../../SharedComponents/DateField/DateField"
import SubMaterials from './SubMaterials';

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "#e6e6e6",
        color: "rgba(0, 0, 0, 0.87)",
        fontWeight: 100,
        maxWidth: 300,
        border: "1px solid #808080",
        marginTop: "-0.1rem",
    }
}))(Tooltip);
class SubGeneral extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            submissionDetails: {},
            isCompareToMandatory: false,
            agencies: [],
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.submissionDetails !== state.submissionDetails) {
            return {
                submissionDetails: props.submissionDetails
            }
        }
        return null
    }

    handleNewCompanyDetails = (companyDetails) => {
        let newValue = {
            value: companyDetails?.agency_id,
            text: companyDetails?.agency_name,
        }
        this.props?.handleListEdit('agency_id', newValue, this.state.companyObj, 'submitted_by')
    }

    handleSubmittedByAgencyOnChange = (field, value, obj, listName, index, agencyRepId = null) => {
        this.setState({ companyIndex: index, companyObj: obj }, () => {
            if (value === null) {
                this.props?.handleListEdit(field, value, obj, listName)
            } else {
                if (!value.hasOwnProperty('agency_type')) {
                    // Add Agency on fly over here
                    this.props?.navFuns?.handleShowAgencyPopup(null, "new", this.handleNewCompanyDetails, agencyRepId)
                } else {
                    this.props?.handleListEdit(field, value, obj, listName)
                }
            }
        })
    }

    handleNewTalentDetails = (talentDetails) => {
        console.log("checking the talent details", talentDetails);
        let newValue = {
            value: talentDetails?.talent_id || talentDetails?.talent_group_id,
            text: (talentDetails?.talent_id ?
                ((talentDetails?.prefix ? talentDetails?.prefix + ' ' : '') + talentDetails?.first_name + ' ' + (talentDetails?.m_i ? talentDetails?.m_i + ' ' : '') + (talentDetails?.last_name ? talentDetails?.last_name : '') + (talentDetails?.suffix ? ' ' + talentDetails?.suffix : '')) :
                this.getFormatTalentGroupName(talentDetails?.talent_group_members_list)),
            type: talentDetails?.talent_id ? 'talent' : 'talent_group',
            company: talentDetails?.company
        }
        this.props?.handleListEdit('submitted_by_id', newValue, this.state.talentObj, 'submitted_by')
    }

    getFormatTalentGroupName = (talentTeamList) => {
        let talentGroupName = [];
        talentTeamList?.map(item => {
            talentGroupName.push(item?.talent_name)
        })
        return talentGroupName?.join(' & ');
    }

    handleNewRepDetails = (details) => {
        let newValue = {
            value: details?.representative_id,
            // text:  details?.first_name + ' ' + (details?.last_name ? details?.last_name : ''),
            text: ((details?.prefix ? details?.prefix + ' ' : '') + details?.first_name + ' ' + (details?.m_i ? details?.m_i + ' ' : '') + (details?.last_name ? details?.last_name : '') + (details?.suffix ? ' ' + details?.suffix : '')),
            type: 'representative',
            company_id: details?.agencies?.[0]?.agency_id || null,
            company: details?.agencies?.[0]?.agency_name      
        }
        this.props?.handleListEdit("submitted_by_id", newValue, this.state.talentObj, 'submitted_by');
    }

    handleSubmittedByOnChange = (field, value, obj, listName, index) => {
        if (field === 'loanout_company') {
            this.props?.handleListEdit(field, value, obj, listName)
        } else {
            this.setState({ talentIndex: index, talentObj: obj }, () => {
                if (value === null) {
                    this.props?.handleListEdit(field, value, obj, listName)
                } else {
                    if (!value.hasOwnProperty('type') && value?.text?.indexOf('Add Rep') == -1) {
                        let talentDetails = {};
                        let prefixDetails = this.props?.getTalentName("prefix", value?.value);
                        if (prefixDetails) {
                            talentDetails['prefix_id'] = prefixDetails?.value;
                            talentDetails['prefix'] = prefixDetails?.label;
                        }
                        talentDetails['first_name'] = this.props?.getTalentName("first_name", value?.value);
                        talentDetails['middle_name'] = this.props?.getTalentName("middle_name", value?.value) || null;
                        talentDetails['last_name'] = this.props?.getTalentName("last_name", value?.value) || null;
                        let suffixDetails = this.props?.getTalentName("suffix", value?.value);
                        if (suffixDetails) {
                            talentDetails['suffix_id'] = suffixDetails?.value;
                            talentDetails['suffix'] = suffixDetails?.label;
                        }

                        //Add on-fly Talent/Talent Team by opening Talent DM Modal
                        this.props?.navFuns?.handleShowTalentPopup(null, 0, "new", this.handleNewTalentDetails, talentDetails)
                    } else if (value?.text?.indexOf('Add Rep') == 0) {
                        // Add Rep on-fly
                        let talentId= null;
                        let repDetails = {}
                        let prefixDetails = this.props?.getTalentName("prefix", value?.value, 'rep');
                        if (prefixDetails) {
                            repDetails['prefix_id'] = prefixDetails?.value;
                            repDetails['prefix'] = prefixDetails?.label;
                        }
                        repDetails['first_name'] = this.props?.getTalentName("first_name", value?.value, 'rep');
                        repDetails['middle_name'] = this.props?.getTalentName("middle_name", value?.value, 'rep') || null;
                        repDetails['last_name'] = this.props?.getTalentName("last_name", value?.value, 'rep') || null;
                        let suffixDetails = this.props?.getTalentName("suffix", value?.value, 'rep');
                        if (suffixDetails) {
                            repDetails['suffix_id'] = suffixDetails?.value;
                            repDetails['suffix'] = suffixDetails?.label;
                        }
                        this.props?.navFuns?.handleShowRepresentativePopup(null,"new", talentId, this.handleNewRepDetails.bind(this), repDetails)
                    } else {
                        this.props?.handleListEdit(field, value, obj, listName)
                    }
                }
            })
        }
    }

    handleDealStepOnChange = (value, obj, listName, index) => {
        let deal_memo_steps = [...this.props?.submissionDetails?.deal_memo_steps];
        if (this.props?.submissionDetails?.project_id != null) {
            deal_memo_steps[index].deal_step_id = value?.value || null;
            deal_memo_steps[index].deal_step = value?.text || null;
        }
        this.props?.handleGeneralEdit(listName, deal_memo_steps)
    }

    handleResetNotify = () => {
        this.setState({ showFail: false, showSuccess: false });
    }

    notificationComponent = (value, mode = "fail") => {
        switch (mode) {
            case "success": return this.setState({ showSuccess: value });
            case "fail": return this.setState({ showFail: value });
        }
    }

    postTalentDetails = (talentDetails, field, selectedValue, obj, listName) => {
        ScripterService.postDataParams(Constants.scripterServiceBaseUrl + '/talent',
            talentDetails,
            this.props?.userContext?.active_tenant?.tenant_id
        )
            .then((response) => {
                if (response?.data?.error) {
                    console.log("Response error: " + response?.data?.error);
                    this.notificationComponent(true, "fail")
                } else {
                    let newTalentItem = {
                        value: response?.data[0]?.talent_id,
                        text: selectedValue + ' - ' + this.props?.formatTalentRepName('talent'),
                        type: 'talent'
                    }
                    this.props?.handleListEdit(field, newTalentItem, obj, listName)
                    this.notificationComponent(true, "success")
                }
            },
                (err) => {
                    console.log("Post talent details error: " + err);
                    this.notificationComponent(true, "fail")
                });
    }

    handleCoverageTypeOnChange = (field, value) => {
        this.props?.handleGeneralEdit(field, value)
    }

    getAgencies = (searchString) => {
        this.setState({ loading: true });
        ScripterService.getData(Constants.scripterServiceBaseUrl + `/agency?searchString=${searchString}`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item =>
                    ({ value: item.agency_id, text: item.agency_name, agency_type: item.agency_type, agency_type_id: item.agency_type_id }));
                console.log(formattedList);
                this.setState({ agencies: formattedList, loading: false });
            },
                (err) => {
                    this.setState({ loading: false });
                    console.log("Error in fetching Agencies:", err)
                })
    }

    handleDivisionChange = (field, value) => {
        if (this.props?.mode === 'Edit' && this.state.submissionDetails?.division_id) {
            this.setState({ 
                showDivisionChangeConfirmation: true,
                submissionDivisionId: value 
            });
        } else {
            this.props?.handleGeneralEdit(field, value);
        }
    }

    render() {
        let severity = this.state.showFail ? "error" : this.state.showSuccess ? "success" : "";
        let messageNotification = this.state.showSuccess ? "Talent is created successfully!" :
            this.state.showFail ? "Saving Talent failed!" : "";
        return (
            <MDBContainer fluid className="sub-general-container">
                <NotificationComponent
                    open={this.state.showSuccess || this.state.showFail}
                    message={messageNotification}
                    severity={severity}
                    handleResetNotify={this.handleResetNotify.bind(this)}
                />
                <MessageModal
                    open={this.state.showDivisionChangeConfirmation}
                    title={"Change Division"}
                    message={"Would you like to change the division?"}
                    primaryButtonText={"Ok"}
                    secondaryButtonText={"Cancel"}
                    onConfirm={() => {
                        this.props?.handleGeneralEdit('division_id', this.state.submissionDivisionId)
                        this.setState({ showDivisionChangeConfirmation: false })
                    }}
                    handleClose={(e) => this.setState({ showDivisionChangeConfirmation: false })} 
                />
                <MDBRow>
                    <MDBCol md={4} className="studioDivision">
                        {(!this.state.submissionDetails?.project_id && this.props?.mode === 'New') ?
                            <SelectField
                                id={"studioDivision"}
                                label={"Studio Division"}
                                defaultMenuText={"-Select-"}
                                isMandatory={false}
                                options={this.props?.divisionDetails || []}
                                value={this.state.submissionDetails?.division_id || ""}
                                // onChange={(e) => this.props?.handleGeneralEdit("division_id", e.target.value)}
                                onChange={(e) => this.handleDivisionChange("division_id", e.target.value)}
                                // disabled={this.state.submissionDetails?.project_id || false}
                            /> : null
                        }
                        {this.props?.mode === 'Edit' ?
                            <SelectField
                                id={"studioDivision"}
                                label={"Studio Division"}
                                defaultMenuText={"-Select-"}
                                isMandatory={false}
                                showAsLabel={(this.props?.mode === "Edit" && this.state.submissionDetails?.project_id)  
                                    ? true : false
                                }
                                options={this.props?.divisionDetails || []}
                                value={this.state.submissionDetails?.division_id || ""}
                                onChange={(e) => this.handleDivisionChange("division_id", e.target.value)}
                            /> : null
                        }
                    </MDBCol>
                    <MDBCol md={3} className="p-0"></MDBCol>
                    <MDBCol className="submission-files p-0">
                        <SubAttachedFiles
                            mode={this.props?.mode}
                            fileRef={this.props?.fileRef}
                            filePayload={this.props?.filePayload}
                            toggleRefreshPage={() => this.props.toggleRefreshPage('submission')}
                            attachedModuleFiles={this.props?.attachedModuleFiles}
                            handleFileUpload={this.props?.handleFileUpload}
                            handleFileDelete={this.props?.handleFileDelete}
                            handleFilePrivate={this.props?.handleFilePrivate}
                            id={"file-upload"}
                        />
                    </MDBCol>
                </MDBRow>
                {/* <BasicLabel type={"darkFieldHeading"} text={"General"} /> */}
                <MDBRow>
                    <MDBCol md={7}>
                        <MDBRow className="submission-row-margin">
                            <MDBCol md={8}>
                                <BasicTextField
                                    id="submissionTitle"
                                    label={"Submission Title"}
                                    isMandatory={true}
                                    limit={limit.submissionTitle}
                                    showMandatory={this.props.postInitiated || false}
                                    limitWarning={messages.exceed300CharacterWarning || ""}
                                    value={this.state.submissionDetails?.submission_title || ""}
                                    onChange={(e) => this.props?.handleGeneralEdit('submission_title', e.target.value?.length > 0 ? e.target.value : null)}
                                    typedCharCount={this.state.submissionDetails?.submission_title?.length || 0}
                                    showCharCount={true}
                                />
                            </MDBCol>
                            <MDBCol md={3} id="h2">
                                <DateField
                                    isMandatory={true}
                                    label={"Received Date"}
                                    showMandatory={this.props.postInitiated || false}
                                    value={this.state.submissionDetails?.received_date || ''}
                                    onChange={(e) => this.props.handleGeneralEdit('received_date', e.target.value)}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={12} className="submission-row-margin">
                                {
                                    this.state.submissionDetails?.akas?.map((aka, index) => (
                                            <MDBRow className="submissionAka">
                                                <MDBCol>
                                                    <BasicLabel 
                                                        text={"AKA(s)"}
                                                        labelClassName={index > 0 ? "akaLabel" : ''}
                                                        type={"text"} 
                                                        charLimit={limit.aka}
                                                        typedCharCount={aka?.aka?.length || 0}
                                                        showCharCount={true}
                                                    />
                                                    <ListField 
                                                        id={"aka" + aka?.submission_aka_id}
                                                        value={aka?.aka || ""}
                                                        showAdd={(this.state.submissionDetails?.akas.length - 1 === index && aka.aka?.length > 0) ? true : false}
                                                        showRemove={!(this.state.submissionDetails?.akas.length === 1) ? true : false}
                                                        // showAsLabel={aka?.submission_aka_id ? true : false}
                                                        limit={limit.aka}
                                                        limitWarning={messages.exceed300CharacterWarning || ""}
                                                        addCallback={() => this.props.addCallback('akas')}
                                                        removeCallback={() => this.props.removeCallback('akas', aka, index)}
                                                        hidePrimary={true}
                                                        onChange={(e) => this.props.handleListEdit("aka", e.target.value.length > 0 ? e.target.value : null, aka, "akas")}
                                                        typedCharCount={aka?.aka?.length || 0}
                                                        showCharCount={true}
                                                    />
                                                </MDBCol>
                                            </MDBRow> 
                                            // : null
                                        // )
                                    ))
                                }
                            </MDBCol>
                            <SubMaterials
                                formats={this.props?.formats || []}
                                postInitiated={this.props?.postInitiated || false}
                                submissionDetails={this.state.submissionDetails || {}}
                                handleGeneralEdit={this.props?.handleGeneralEdit}
                                handleListEdit={this.props?.handleListEdit}
                                addCallback={this.props?.addCallback}
                                removeCallback={this.props?.removeCallback}
                                getEntity={this.props?.getEntity}
                                userList={this.props?.userList}
                                navFuns={this.props?.navFuns}
                                getTalentName={this.props?.getTalentName}
                            />
                        </MDBRow>

                    </MDBCol>
                    <MDBCol md={5}>
                        <MDBRow className="submission-row-margin">
                            {this.state.submissionDetails?.submitted_to?.map((submittedTo, index) => (
                                (submittedTo.is_delete === 0 ?
                                    <MDBRow className="submitted-margin p-0 ">
                                        <MDBCol md={10}>
                                            <SearchSelectField
                                                id={"submittedTo" + index}
                                                label={index === 0 ? "Submitted To" : ""}
                                                placeholder={'- Search -'}
                                                options={this.props?.submittedToList || []}
                                                detail_selected={submittedTo?.submitted_to_id && submittedTo?.submitted_to ? {
                                                    value: submittedTo?.submitted_to_id,
                                                    text: submittedTo?.submitted_to
                                                } : null}
                                                width={'100%'}
                                                valueSelected={(e, selected) => this.props?.handleListEdit("submitted_to_id", selected, submittedTo, "submitted_to")}
                                                searchText={(e) => this.props?.getEntity('submittedToList', 'USER', e.target.value, 'Submitted To')}
                                                multiple={false}
                                                searchSelect={true}
                                            />
                                        </MDBCol>
                                        <MDBCol md={2} className="actionButons2 d-inline-block">
                                            <MDBRow className={`${index === 0 ? 'initialIndexIcons' : ''}`}>
                                                <MDBCol md={1} className="removeActionIcon">
                                                    {
                                                        (submittedTo.submitted_to?.length > 0) ?
                                                            <MDBIcon
                                                                icon="trash-alt"
                                                                className="float-right"
                                                                onClick={() => this.props?.removeCallback("submitted_to", submittedTo, index)}
                                                            />
                                                            : null
                                                    }
                                                </MDBCol>
                                                <MDBCol
                                                    md={1}
                                                    className={`${this.state.submissionDetails?.submitted_to?.length === 1 ? 'addIconMargin' : ''}`}
                                                >
                                                    {
                                                        (submittedTo.submitted_to?.length > 0 && index === (this.state.submissionDetails?.submitted_to.length - 1)) ?
                                                            <AddCircleIcon
                                                                onClick={(e) =>
                                                                    this.props?.addCallback("submitted_to")
                                                                }
                                                            /> : null
                                                    }
                                                </MDBCol>
                                            </MDBRow>
                                        </MDBCol>
                                    </MDBRow>
                                    : null
                                )
                            ))}
                        </MDBRow>
                        <MDBRow>
                            {this.state.submissionDetails?.submitted_by?.map((submittedBy, index) => (
                                (submittedBy.is_delete === 0 ?
                                    <MDBRow className="submitted-margin submitted-by p-0">
                                        <MDBCol md={5} className="pr-0">
                                            <BasicLabel text={index === 0 ? "Submitted By" : ""} />
                                            <SearchFieldWithAddValue
                                                id={"submittedBy" + index}
                                                placeholder={'- Search -'}
                                                options={this.props?.submittedByList || []}
                                                showMultipleAddOption={true}
                                                primaryAddText={"Add Talent -"}
                                                secondaryAddText={"Add Rep -"}
                                                detail_selected={(submittedBy?.talent_id || submittedBy?.talent_group_id || submittedBy?.representative_id) ? {
                                                    value: submittedBy?.talent_id ? submittedBy?.talent_id :
                                                        submittedBy?.talent_group_id ? submittedBy?.talent_group_id :
                                                            submittedBy?.representative_id ? submittedBy?.representative_id : null,
                                                    text: submittedBy?.submitted_by
                                                } : null}
                                                width={'100%'}
                                                valueSelected={(e, selected) => this.handleSubmittedByOnChange("submitted_by_id", selected, submittedBy, "submitted_by")}
                                                searchText={(e) => this.props?.getEntity('submittedByList', 'TALENT_REP', e.target.value, 'Submitted By')}
                                                multiple={false}
                                                searchSelect={true}
                                            />
                                        </MDBCol>
                                        <MDBCol md={5} className="pl-0">
                                            <>
                                                {(submittedBy?.talent_id || submittedBy?.talent_group_id) ?
                                                    <BasicTextField
                                                        id="company"
                                                        label={index === 0 ? "Company" : ''}
                                                        width={'100%'}
                                                        value={submittedBy?.loanout_company || ""}
                                                        onChange={(e) => this.handleSubmittedByOnChange('loanout_company', e.target.value?.length > 0 ? e.target.value : null, submittedBy, "submitted_by")}
                                                    /> : null
                                                }
                                                {(submittedBy?.representative_id) ?
                                                    <div className="rep-company">
                                                        <BasicLabel text={index === 0 ? "Company" : ""} />
                                                        <SearchFieldWithAddValue
                                                            id={"company" + index}
                                                            placeholder={'- Search -'}
                                                            options={this.state.agencies || []}
                                                            detail_selected={submittedBy?.agency_id ? {
                                                                value: submittedBy?.agency_id,
                                                                text: this.state.agencies?.find(agency => agency?.value === submittedBy?.agency_id)?.text || submittedBy?.loanout_company
                                                            } : null}
                                                            width={'100%'}
                                                            valueSelected={(e, selected) => this.handleSubmittedByAgencyOnChange("agency_id", selected, submittedBy, "submitted_by", index, submittedBy?.representative_id)}
                                                            searchText={(e) => this.getAgencies(e.target.value)}
                                                            multiple={false}
                                                            searchSelect={true}
                                                        />
                                                    </div> : null
                                                }
                                                {(!submittedBy?.talent_id && !submittedBy?.talent_group_id && !submittedBy?.representative_id) ?
                                                    <BasicTextField
                                                        id="company"
                                                        label={index === 0 ? "Company" : ''}
                                                        width={'100%'}
                                                        disabled={true}
                                                    /> : null
                                                }
                                            </>
                                        </MDBCol>
                                        <MDBCol md={2} className="actionButons2 d-inline-block">
                                            <MDBRow className={`${index === 0 ? 'initialIndexIcons' : ''}`}>
                                                <MDBCol md={1} className="removeActionIcon">
                                                    {
                                                        (submittedBy.submitted_by?.length > 0) ?
                                                            <MDBIcon
                                                                icon="trash-alt"
                                                                className="float-right"
                                                                onClick={() => this.props?.removeCallback("submitted_by", submittedBy, index)}
                                                            />
                                                            : null
                                                    }
                                                </MDBCol>
                                                <MDBCol
                                                    md={1}
                                                    className={`${this.state.submissionDetails?.submitted_by?.length === 1 ? 'addIconMargin' : ''}`}
                                                >
                                                    {
                                                        (submittedBy.submitted_by?.length > 0 && index === (this.state.submissionDetails?.submitted_by.length - 1)) ?
                                                            <AddCircleIcon
                                                                onClick={(e) =>
                                                                    this.props?.addCallback("submitted_by")
                                                                }
                                                            /> : null
                                                    }
                                                </MDBCol>
                                            </MDBRow>
                                        </MDBCol>
                                    </MDBRow>
                                    : null
                                )
                            ))}
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
                <MDBRow className="submission-row-margin">
                    <MDBCol md={4} className="coverageType">
                        <SelectField
                            id={"coverageType"}
                            label={"Coverage Type"}
                            defaultMenuText={"-Select-"}
                            isMandatory={true}
                            options={this.props?.coverageTypes || []}
                            mandatoryWarning={messages.mandatoryWarning}
                            showMandatory={this.props?.postInitiated || false}
                            value={this.state.submissionDetails?.coverage_type_id || ""}
                            // onChange={(e, value) => this.props?.handleGeneralEdit('coverage_type', e.target.value)}
                            onChange={(e, value) => this.handleCoverageTypeOnChange('coverage_type', e.target.value)}
                        />
                    </MDBCol>
                    <MDBCol md={6}>
                        {
                            this.props.submissionDetails?.coverage_type === "Amended Writing Credit Determination" || this.props.submissionDetails?.coverage_type === "Full Project Comparison" || this.props.submissionDetails?.coverage_type === "Legal Addendum" || this.props.submissionDetails?.coverage_type === "Legal Amendment" || this.props.submissionDetails?.coverage_type === "Legal" || this.props.submissionDetails?.coverage_type === "Second Opinion" || this.props.submissionDetails?.coverage_type === "Second Opinion Project" || this.props.submissionDetails?.coverage_type === "Third Opinion" || this.props.submissionDetails?.coverage_type === "Top Sheet Comparison" || this.props.submissionDetails?.coverage_type === "Extended Comparison" ?
                                this.props.submissionDetails?.compare_to?.map((compareTo, index) => (
                                    (compareTo.is_delete === 0 ?
                                        <MDBRow className="compare-to-margin">
                                            <MDBCol md={10}>
                                                <SearchSelectField
                                                    id={"compare-to" + compareTo?.compare_to_id}
                                                    label={index === 0 ? "Compare To" : ""}
                                                    placeholder={'- Search -'}
                                                    isMandatory={this.props.submissionDetails?.coverage_type === "Legal" ? false : true}
                                                    showMandatory={(this.props?.postInitiated && this.props?.compareToPostInitiated && this.props.submissionDetails?.coverage_type !== "Legal") || false}
                                                    options={this.props.submissionList || []}
                                                    detail_selected={compareTo?.compare_to_id && compareTo?.compare_to ? {
                                                        value: compareTo?.compare_to_id,
                                                        text: compareTo?.compare_to
                                                    } : null}
                                                    width={'100%'}
                                                    valueSelected={(e, value) => this.props?.handleListEdit("compare_to_id", value, compareTo, "compare_to")}
                                                    searchText={(e) => this.props?.getEntity('submissionList', 'SUBMISSION', e.target.value)}
                                                    multiple={false}
                                                    searchSelect={true}
                                                    loading={this.props?.loading}
                                                />
                                            </MDBCol>
                                            <MDBCol md={2} className="actionButons2 d-inline-block">
                                                <MDBRow className={`${index === 0 ? 'initialIndexIcons' : ''}`}>
                                                    <MDBCol md={1} className="removeActionIcon">
                                                        {
                                                            (compareTo?.compare_to?.length > 0) ?
                                                                <MDBIcon
                                                                    icon="trash-alt"
                                                                    className="float-right"
                                                                    onClick={() => this.props?.removeCallback("compare_to", compareTo, index)}
                                                                />
                                                                : null
                                                        }
                                                    </MDBCol>
                                                    <MDBCol
                                                        md={1}
                                                        className={`${this.props?.submissionDetails?.compare_to?.length === 1 ? 'addIconMargin' : ''}`}
                                                    >
                                                        {
                                                            (compareTo.compare_to?.length > 0 && index === (this.props?.submissionDetails?.compare_to.length - 1)) ?
                                                                <AddCircleIcon
                                                                    onClick={(e) =>
                                                                        this.props?.addCallback("compare_to")
                                                                    }
                                                                /> : null
                                                        }
                                                    </MDBCol>
                                                </MDBRow>
                                            </MDBCol>
                                        </MDBRow>
                                        : null
                                    )
                                ))
                                : null
                        }
                        {this.props.showCoverageWarningGreaterThanFive ? <span className="errorText">Cannot enter more than 5 compare-to</span> : null}
                    </MDBCol>
                    <MDBCol md={2} className="sub-due-date">
                        <DateField
                            // isMandatory={true}
                            label={"Coverage Due Date"}
                            // showMandatory={this.props.postInitiated || false}
                            value={this.state.submissionDetails?.due_date || ''}
                            onChange={(e) => this.props.handleGeneralEdit('due_date', e.target.value)}
                        />
                        {this.props?.showDateError ?
                            // <p className="error">Due Date can not be before the current day</p>
                            <p className="error">Due Date should be equal to or greater than received date</p>
                            : null
                        }
                    </MDBCol>
                </MDBRow>
                <MDBRow className="submission-row-margin">
                    <MDBCol md={4}>
                        <SearchSelectField
                            id={"project"}
                            label={"Project"}
                            placeholder={'- Search -'}
                            options={this.props?.projectList || []}
                            detail_selected={this.state.submissionDetails?.project_id && this.state.submissionDetails?.project ? {
                                value: this.state.submissionDetails?.project_id,
                                text: this.state.submissionDetails?.project
                            } : null}
                            width={'100%'}
                            valueSelected={(e, selected) => this.props?.handleGeneralEdit('project', selected)}
                            searchText={(e) => this.props?.getEntity('projectList', 'PROJECT', e.target.value)}
                            multiple={false}
                            searchSelect={true}
                        />
                    </MDBCol>
                    {/* <MDBCol md={1} className="set-project-col-width"></MDBCol> */}
                    <MDBCol md={6}>
                        {
                            this.props.submissionDetails?.deal_memo_steps?.map((dealStep, index) => (
                                (dealStep.is_delete === 0 ?
                                    <MDBRow className={`${!this.props?.submissionDetails?.project_id ? 'disabled-deal-step' : ''} deal-step-margin`}>
                                        <MDBCol md={10}>
                                            <SearchSelectField
                                                id="deal-step"
                                                label={index === 0 ? "Deal Step" : ""}
                                                placeholder={'- Select -'}
                                                options={this.props?.dealSteps || []}
                                                detail_selected={this.props?.submissionDetails?.project_id && dealStep?.deal_step_id && dealStep?.deal_step ? {
                                                    value: dealStep?.deal_step_id,
                                                    text: dealStep?.deal_step
                                                } : null}
                                                width={'100%'}
                                                valueSelected={(e, value) => this.handleDealStepOnChange(value, dealStep, "deal_memo_steps", index)}
                                                searchText={(e) => this.props?.getEntity('dealSteps', 'DEAL_STEPS', e.target.value)}
                                                multiple={false}
                                                searchSelect={true}
                                                disabled={!this.props?.submissionDetails?.project_id || false}
                                                highlightSelectedItem={this.props?.linkedDealSteps}
                                            />
                                        </MDBCol>
                                        <MDBCol md={2} className="actionButons2 d-inline-block">
                                            <MDBRow className={`${index === 0 ? 'initialIndexIcons' : ''}`}>
                                                <MDBCol md={1} className="removeActionIcon">
                                                    {
                                                        (dealStep.deal_step?.length > 0) ?
                                                            <MDBIcon
                                                                icon="trash-alt"
                                                                className="float-right"
                                                                onClick={() => this.props?.removeCallback("deal_memo_steps", dealStep, index)}
                                                            />
                                                            : null
                                                    }
                                                </MDBCol>
                                                <MDBCol
                                                    md={1}
                                                    className={`${this.props?.submissionDetails?.deal_memo_steps?.length === 1 ? 'addIconMargin' : ''}`}
                                                >
                                                    {
                                                        (dealStep.deal_step?.length > 0 && index === (this.props?.submissionDetails?.deal_memo_steps.length - 1)) ?
                                                            <AddCircleIcon
                                                                onClick={(e) =>
                                                                    this.props?.addCallback("deal_memo_steps")
                                                                }
                                                            /> : null
                                                    }
                                                </MDBCol>
                                            </MDBRow>
                                        </MDBCol>
                                    </MDBRow>
                                    : null
                                )
                            ))
                        }
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        );
    }
}

export default withUserContext(SubGeneral)