import React from 'react';
import SubGeneral from './SubGeneral';
import SubMaterials from './SubMaterials';
import SubSourceMaterials from './SubSourceMaterials';
import EditElements from './EditElements';
import SubAttachedFiles from './SubAttachedFiles';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import BasicTextArea from '../../../SharedComponents/BasicTextArea/BasicTextArea';
import BasicButton from '../../../SharedComponents/BasicButton/BasicButton';
import BasicLabel from '../../../SharedComponents/BasicLabel/BasicLabel';
import ScripterService from '../../../../services/service';
import * as Constants from '../../../../constants/constants';
import MessageModal from '../../../SharedComponents/MessageModal';
import messages from '../../../../Common/Messages.json';
import sizeLimit from '../../../../Common/SizeLimits.json';
import CircularProgress from "@material-ui/core/CircularProgress";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import moment from "moment";
import {
    subMockData,
    limit,
    subAka,
    subAuthor,
    subElement,
    subSourceMaterial,
    submittedTo,
    submittedBy,
    compareTo,
    dealMemoSteps,
    submissionWorkflowMockData,
    filePayload,
    talentPrefixes,
    talentSuffixes
} from './Config';
import './Submissions.scss';
import { withUserContext } from '../../../../contexts/UserContext';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import BasicCheckBox from '../../../SharedComponents/BasicCheckbox/BasicCheckbox';
import BasicTextField from '../../../SharedComponents/BasicTextField/BasicTextField';
import { validateCharNumOnly, getFormattedDate } from '../../../../Common/Helper';
import { projectStatusesMakePrivate } from '../../../MiscFiles/config';

export default withRouter(withUserContext(class Submission extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deletedCompareTo: [],
            submissionDetails: this.formatSubmissionList(JSON.parse(JSON.stringify({ ...subMockData }))),
            coverageTypes: [],
            isFetchingCoverageTypes: false,
            formats: [],
            isFetchingFormats: false,
            types: [],
            isFetchingTypes: false,
            statuses: [],
            isFetchingStatuses: false,
            roles: [],
            talentList: [],
            isFetchingRoles: false,
            postInitiated: false,
            compareToPostInitiated: false,
            akaIndex: 2,
            authorIndex: 2,
            elementIndex: 2,
            sourceIndex: 2,
            submittedToIndex: 2,
            submittedByIndex: 2,
            compareToIndex: 2,
            userList: [],
            submittedToList: [],
            submittedByList: [],
            projectList: [],
            dealSteps: [],
            submissionList: [],
            isUserListLoading: false,
            isProjectListLoading: false,
            isDealStepsLoading: false,
            isPosting: false,
            isFetchingSubmissionDetails: false,
            isOpen: false,
            inActiveAkas: [],
            inActiveAuthors: [],
            inActiveSourceMaterials: [],
            inActiveElements: [],
            attachedFile: null,
            submissionFiles: [],
            deletedFileList: [],
            submittedToGroupName: [],
            submittedByGroupName: [],
            searchCancelToken: null,
            isPrivateFileList: [],
            filePrefix: false,
            isDeleteFile: null,
            divisionDetails: [],
            showCoverageWarningGreaterThanFive: false,
            project_status_id: null
        }
        this.fileRef = React.createRef();
        this.subGeneralRef = React.createRef();
    }

    async componentDidMount() {
        if (this.props.submissionId) {
            await this.fetchSubmissionDetails();
            this.getFiles();
        }
        this.getFormats();
        this.getTypes();
        this.getStatuses();
        this.getRoles();
        this.getGroups();
        this.getCoverageTypes();
        this.getEntity('dealSteps', 'DEAL_STEPS', '')
        this.setCurrentReceivedDate();
        this.getDivisions()
        this.getStaticData('repPrefixes', 'lu_prefix');
        this.getStaticData('repSuffixes', 'lu_suffix');
    }

    componentDidUpdate(prevProps, prevState) {
        let canAddPrivateSubmissionFiles = this.props?.userContext?.active_tenant?.permissions?.canAddPrivateSubmissionFiles === true ? true : false
        if (prevState.submissionDetails?.project_id !== this.state.submissionDetails?.project_id) {
            this.getEntity('dealSteps', 'DEAL_STEPS', '')
            // this.setState({ dealSteps: [] })
        }
        if (prevState?.submissionFiles?.length !== this.state?.submissionFiles?.length) {
            if (this.state.submissionDetails?.is_confidential) {
                let submissionFiles = [...this.state?.submissionFiles];
                let newItems = submissionFiles[submissionFiles.length-1];
                    canAddPrivateSubmissionFiles ? newItems.isPrivate = 1 : newItems.isPrivate = 0;
                this.setState({ submissionFiles: submissionFiles })
            }
        }
    }

    getStaticData = (listType, staticTableName) => {
        ScripterService.getData(Constants.scripterServiceBaseUrl + `/staticData?staticTable=${staticTableName}`, 
            this.props.userContext?.active_tenant?.tenant_id)
        .then(response => {
            let formattedList = response.data?.map(item => ({ value: item.id, label: item.name, is_active: item.is_active }));
            this.setState({ [listType]: formattedList });
        },
        (err) => {
            console.log("Error in fetching Email Types:", err)
        })
    }
    
    setDefaultDivision = () => {
        if (!this.state.submissionDetails?.division_id || !this.state.submissionDetails?.project_id) {
            let findWbPictureDivision = this.state.divisionDetails?.find(item => item.label === 'WB Pictures');
            if (findWbPictureDivision) {
                this.setState(prevState => ({
                    submissionDetails: {
                        ...prevState.submissionDetails,
                        division_id: findWbPictureDivision?.value || null
                    },
                    postInitiated: false
                }))
            } else {
                this.setState(prevState => ({
                    submissionDetails: {
                        ...prevState.submissionDetails,
                        division_id: this.state.divisionDetails?.[0]?.value || null
                    },
                    postInitiated: false
                }))
            }
        }
    }

    setCurrentReceivedDate = () => {
        let currentDate = this.formatDate(new Date());
        this.setState(prevState => ({
            submissionDetails: {
                ...prevState.submissionDetails,
                received_date: currentDate || null
            },
            postInitiated: false
        }))
    }

    formatDate(date) {
        return moment(date).format('YYYY-MM-DD');
    }

    fetchSubmissionDetails = () => {
        if (this.props.submissionId) {
            this.setState({ isFetchingSubmissionDetails: true });
            ScripterService.getData(Constants.scripterServiceBaseUrl + `/submission?submissionId=${this.props.submissionId}`,
                this.props.userContext.active_tenant.tenant_id)
                .then(response => {
                    this.setState({ isFetchingSubmissionDetails: false });
                    if (response.data.error) {
                        // console.log("response error", response)
                    } else {
                        console.log("response", response?.data[0])
                        let submissionDetails = response?.data[0];
                        submissionDetails.akas = submissionDetails?.akas?.filter(item => item?.aka !== null && item?.aka !== 'null');
                        submissionDetails?.elements?.map((item, index) => {
                            item.index = index + 1
                            item.talent_selected = {
                                value: item?.talent_id || item?.talent_group_id,
                                text: item?.element_name,
                                type: "talent",
                                writerTeam: item?.writer_team
                            }
                            item.talentOptions = [{
                                value: item?.talent_id || item?.talent_group_id,
                                text: item?.element_name,
                                type: "talent",
                                writerTeam: item?.writer_team
                            }]
                            return item;
                        })
                        submissionDetails?.submitted_by?.map(item => {
                            let submittedByName = item?.talent_id ? item?.talent_name :
                                item?.talent_group_id ? item?.talent_group_name :
                                    item?.representative_id ? item?.representative_name : null;
                            let submittedByType = item?.talent_id ? 'Talent' :
                                item?.talent_group_id ? 'Talent Group' :
                                    item?.representative_id ? 'Representative' : null;
                            item.submitted_by = submittedByName + ' - ' + submittedByType;
                            item['is_company_change'] = 0;
                            return item;
                        })
                        submissionDetails.is_pgs = (submissionDetails?.is_pgs === null && submissionDetails?.is_wb_pgs === null) ?
                            1 : submissionDetails?.is_pgs;
                        if (submissionDetails?.is_none_deal_step === 1) {
                            let noneItem = {
                                deal_step_id: "None",
                                deal_step: "None",
                                alwaysShow: true,
                                is_delete: 0
                            }
                            submissionDetails.deal_memo_steps = [{ ...noneItem }];
                        }
                        submissionDetails?.deal_memo_steps?.map(item => {
                            item.deal_step = (item?.deal_step_writers ? (item?.deal_step_writers + '; ') : '') + (item?.deal_step_number ? (item?.deal_step_number + '; ') : '') + item?.deal_step;
                            item.deal_step_id = item?.deal_step === "None" ? "None" : item.deal_step_id;
                            return item;
                        })
                        submissionDetails?.compare_to?.map(item => {
                            item.compare_to = item.compare_to ? (item?.compare_to + ' - Rcvd. ' + (item?.compare_to_received_date ? item.compare_to_received_date : "")) : null;
                            return item;
                        })
                        submissionDetails.due_date = submissionDetails?.submission_due_date;
                        submissionDetails.division_id = submissionDetails?.division_id;
                        submissionDetails.project = submissionDetails?.project_aka != null ? submissionDetails?.project + " + " + submissionDetails?.project_aka : submissionDetails?.project
                        // this.subGeneralRef.current.checkIfCompareToMandatory(submissionDetails?.coverage_type_id, submissionDetails?.coverage_type);
                        this.setState({
                            submissionDetails: this.formatSubmissionList(JSON.parse(JSON.stringify({ ...submissionDetails }))),
                            isFetchingSubmissionDetails: false,
                            project_status_id: submissionDetails?.project_status_id
                        }, () => {
                            this.setState({
                                elementIndex:this.state.submissionDetails?.elements?.length > 1 ? this.state.submissionDetails?.elements?.length + 1 : 2,
                                isConfidential: this.state.submissionDetails?.is_confidential
                            })
                        })
                    }
                },
                    (err) => {
                        this.setState({ isFetchingSubmissionDetails: false });
                        console.log("Error in fetching submission details:", err)
                    })
        }
    }

    getFiles() {
        // let canAddRemoveViewPrivateSubmissionFiles = this.props?.userContext?.active_tenant?.permissions?.canAddRemoveViewPrivateSubmissionFiles === true ? true : false
        let canAddPrivateSubmissionFiles = this.props?.userContext?.active_tenant?.permissions?.canAddPrivateSubmissionFiles === true ? true : false
        let canViewPrivateSubmissionFiles = this.props?.userContext?.active_tenant?.permissions?.canViewPrivateSubmissionFiles === true ? true : false
        let canRemovePrivateSubmissionFiles = this.props?.userContext?.active_tenant?.permissions?.canRemovePrivateSubmissionFiles === true ? true : false
        let payload = {
            ...filePayload,
            submissionId: this.props?.submissionId,
            type: "submission",
            module: 'submission',
            subModule: 'misc',
            tenantName: this.props?.userContext?.active_tenant?.tenant_name,
            // isPrivate: canAddRemoveViewPrivateSubmissionFiles ? 1 : 0
            isPrivate: (canAddPrivateSubmissionFiles || canViewPrivateSubmissionFiles || canRemovePrivateSubmissionFiles) ? 1 : 0 
        }
        ScripterService.getDataParams(Constants.scripterServiceBaseUrl + `/getFiles`, payload, this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                if (response.data?.error) {
                    console.log("Error from fetch file", response.data?.error)
                    return;
                }
                let formattedFileList = response.data?.Contents?.map((file) => {
                    return {
                        "Key": file?.Key,
                        "name": file.Key,
                        "url": file.url,
                        "isDownloadable": true,
                        type: "submission",
                        isPrivate: file?.isPrivate,
                    }
                })
                this.setState({ 
                    submissionFiles: formattedFileList, 
                    filePrefix: response.data?.Prefix
                })
            }, err => {
                console.log('from misc files', err)
            })
    }

    getFormats = () => {
        this.setState({ isFetchingFormats: true });
        ScripterService.getData(Constants.scripterServiceBaseUrl + '/staticData?staticTable=lu_format',
            this.props.userContext.active_tenant.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({
                    value: item.id,
                    text: item.name,
                    is_active: item.is_active
                }));
                this.setState({ formats: formattedList, isFetchingFormats: false });
            },
                (err) => {
                    this.setState({ isFetchingFormats: false });
                    console.log("Error in fetching formats:", err)
                })
    }

    getTypes = () => {
        this.setState({ isFetchingTypes: true });
        ScripterService.getData(Constants.scripterServiceBaseUrl + '/staticData?staticTable=lu_material_type',
            this.props.userContext.active_tenant.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({
                    value: item.id,
                    text: item.name,
                    is_active: item.is_active
                }));
                this.setState({ types: formattedList, isFetchingTypes: false });
            },
                (err) => {
                    this.setState({ isFetchingTypes: false });
                    console.log("Error in fetching types:", err)
                })
    }

    getStatuses = () => {
        this.setState({ isFetchingStatuses: true });
        ScripterService.getData(Constants.scripterServiceBaseUrl + '/staticData?staticTable=lu_element_status',
            this.props.userContext.active_tenant.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({
                    value: item.id,
                    label: item.name,
                    is_active: item.is_active
                }));
                this.setState({ statuses: formattedList, isFetchingStatuses: false });
            },
                (err) => {
                    this.setState({ isFetchingStatuses: false });
                    console.log("Error in fetching statuses:", err)
                })
    }
    getTalents = (event, element) => {
        if (event.target.value !== "" || event.target.value !== null) {
            this.setState({ loading: true });
            ScripterService.getData(
                Constants.scripterServiceBaseUrl +
                `/entitySearch?entity=TALENT&searchString=${event.target.value}`,
                this.props.userContext.active_tenant.tenant_id
            )
                .then((response) => {

                    let formattedList = response?.data?.map((item) => ({
                        value: item?.talent_id || item?.talent_group_id,
                        text: item?.talent_name,
                        writerTeam: item?.writer_team,
                        type: "talent",

                    }));
                    let updateElement = [...this.state?.submissionDetails?.elements?.map(ele => {
                        if (ele?.index === element?.index) {
                            ele.talentOptions = formattedList
                            ele.index = element?.index
                        }
                        return ele;
                    })]
                    this.setState(prevState => ({
                        submissionDetails: {
                            ...prevState.submissionDetails,
                            elements: updateElement
                        },
                        talentOptions: formattedList,
                        loading: false,
                    }));
                })
                .catch((err) => {
                    console.log("error", err);
                    this.setState({ loading: false });
                });
        }

    }
    getFormatTalentGroupName = (talentTeamList) => {
        let talentGroupName = [];
        talentTeamList?.map(item => {
            talentGroupName.push(item?.talent_name)
        })
        return talentGroupName?.join(' & ');
    }
    handleNewTalentDetails(talentDetails) {
        let value = {
            value: talentDetails?.talent_id || talentDetails?.talent_group_id,
            text: (talentDetails?.talent_id ?
                // (talentDetails?.first_name + ' ' + (talentDetails?.last_name ? talentDetails?.last_name : '')) :
                ((talentDetails?.prefix ? talentDetails?.prefix + ' ' : '') + talentDetails?.first_name + ' ' + (talentDetails?.m_i ? talentDetails?.m_i + ' ' : '') + (talentDetails?.last_name ? talentDetails?.last_name : '') + (talentDetails?.suffix ? ' ' + talentDetails?.suffix : '')) :
                this.getFormatTalentGroupName(talentDetails?.talent_group_members_list) || null),
            writerTeam: talentDetails?.writer_team,
            type: "talent"
        }
        let obj = {
            index: this.state?.currentIndex
        }
        this.handleListEdit('name', value, obj, 'elements')
    }
    selectedTalent = (value, obj) => {
        this.setState({ currentIndex: obj?.index })
        if (value && !value?.hasOwnProperty('type')) {
            let talentDetails = {}
            let prefixDetails = this.getTalentName("prefix", value?.value);
            if (prefixDetails) {
                talentDetails['prefix_id'] = prefixDetails?.value;
                talentDetails['prefix'] = prefixDetails?.label;
            }
            talentDetails['first_name'] = this.getTalentName("first_name", value?.value);
            talentDetails['middle_name'] = this.getTalentName("middle_name", value?.value);
            talentDetails['last_name'] = this.getTalentName("last_name", value?.value) || null;
            let suffixDetails = this.getTalentName("suffix", value?.value);
            if (suffixDetails) {
                talentDetails['suffix_id'] = suffixDetails?.value;
                talentDetails['suffix'] = suffixDetails?.label;
            }
            this.props?.navFuns?.handleShowTalentPopup(null, 0, "new", this.handleNewTalentDetails.bind(this), talentDetails)
        }
        else {
            this.handleListEdit('name', value, obj, 'elements')
        }
    }
    getTalentName = (field, value, type = null) => {
        let prefixes = type === 'rep' ? [ ...this.state.repPrefixes ] : [ ...talentPrefixes ];
        let suffixes = type === 'rep' ? [ ...this.state.repSuffixes ] : [ ...talentSuffixes ];
        let n = value?.split(" ");
        if (field === "first_name" || field === "middle_name" || field === "last_name") {
            let prefixExist = prefixes?.find(item => item?.label?.toLowerCase()?.includes(n?.[0]?.toLowerCase()));
            if (prefixExist) {
                n.splice(0, 1);
            }
            let suffixExist = suffixes?.find(item => item?.label?.toLowerCase()?.includes(n?.[n?.length - 1]?.toLowerCase()));
            if (suffixExist) {
                n.pop();
            }
        }
        if (field === "first_name") {
            if (n?.length > 0) {
                return n[0];
            }
        } else if (field === "middle_name") {
            if (n?.length > 2) {
                return n[1];
            }
        } else if (field === "last_name") {
            if (value.split(" ").length > 1) {
                let lastName = [];
                n?.map((item, index) => {
                    if (n?.length <= 2 && index !== 0) {
                        lastName.push(item)
                    } else if (n?.length > 2 && index !== 0 && index !== 1) {
                        lastName.push(item)
                    }
                })
                return lastName.join(" ");
            }
        } else if (field === "prefix") {
            let prefix = prefixes?.find(item => item?.label?.toLowerCase()?.includes(n?.[0]?.toLowerCase()));
            return prefix;
        } else if (field === "suffix") {
            let suffix = suffixes?.find(item => item?.label?.toLowerCase()?.includes(n?.[n?.length -1]?.toLowerCase()));
            return suffix;
        }
    }
    getRoles = () => {
        this.setState({ isFetchingRoles: true });
        ScripterService.getData(Constants.scripterServiceBaseUrl + '/staticData?staticTable=lu_talent_roles',
            this.props.userContext.active_tenant.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({
                    value: item.id,
                    label: item.name,
                    is_active: item.is_active
                }));
                this.setState({ roles: formattedList, isFetchingRoles: false });
            },
                (err) => {
                    this.setState({ isFetchingRoles: false });
                    console.log("Error in fetching roles:", err)
                })
    }

    getEntity = (entity, entitytype, searchString, field = null) => {
        if (this.state.searchCancelToken != null)
            this.state.searchCancelToken.cancel("Operation canceled due to new request");
        var cancelToken = axios.CancelToken.source();
        this.setState({ searchCancelToken: cancelToken });
        let loading = (entitytype === 'PROJECT') ? 'isProjectListLoading' : (entitytype === 'USER') ? 
            'isUserListLoading' : (entitytype === 'SUBMISSION') ? 'isSubmissionLoading' : 'loading';
        this.setState({ [loading]: true });
        let groupName = (field === 'Submitted To' ? this.state.submittedToGroupName : this.state.submittedByGroupName);
        let url = (entitytype === 'USER') ?
            `/entitySearch?entity=${entitytype}&groupName=${groupName}&searchString=${searchString}` :
            (entitytype === 'DEAL_STEPS' && this.state.submissionDetails?.project_id) ?
                `/entitySearch?entity=${entitytype}&searchString=${searchString}&projectId=${this.state.submissionDetails?.project_id}` :
                `/entitySearch?entity=${entitytype}&searchString=${searchString}`;
        ScripterService.getDataWithCancel(Constants.scripterServiceBaseUrl + url,
            cancelToken,
            this.props.userContext.active_tenant.tenant_id)
            .then(response => {
                let formattedList = [];
                if (entitytype === 'PROJECT') {
                    formattedList = response?.data?.map(item => ({ value: item.project_id, text: item.aka != null ? item.project_title + " + " + item.aka : item.project_title,project_status_id:item.project_status_id }));
                } else if (entitytype === 'USER') {
                    formattedList = response?.data?.map(item => ({ value: item.user_id, text: item.user_name }));
                } else if (entitytype === 'DEAL_STEPS') {
                    formattedList = response?.data?.map(item => ({ value: item.deal_memo_step_id, 
                        text: item.deal_step_writers + '; ' + (item.step_number ? (item.step_number + '; ') : '') + item.step_name + (item?.is_ammendment ? ` (Amendment - ${getFormattedDate(item?.ammendment_date)})` : ''), 
                        is_selected_in_submission: item.is_selected_in_submission, 
                        is_amendment: item?.is_ammendment, 
                        ammendment_date: item?.ammendment_date 
                    }));
                    let noneItem = {
                        value: "None",
                        text: "None",
                        alwaysShow: true
                    }
                    formattedList.unshift(noneItem);
                    let linkedDealSteps = [];
                    formattedList?.map(item => {
                        if (item.is_selected_in_submission) {
                            linkedDealSteps.push(item.value);
                        }
                    })
                    this.state.submissionDetails?.deal_memo_steps?.map(item => {
                        let amendmentStep = formattedList?.find(dealItem => dealItem?.value === item.deal_step_id);
                        item.deal_step = amendmentStep?.text;
                        return item;
                    })
                    this.setState({ linkedDealSteps: linkedDealSteps })
                } else if (entitytype === 'SUBMISSION') {
                    formattedList = response?.data?.map(item => ({ value: item.submission_id, text: item.title + (item.received_date ? ` - Rcvd. ${item.received_date}` : ''), submissionRcvdDate: item.received_date }));
                    formattedList.sort(function (a, b) {
                        return new Date(b.submissionRcvdDate) - new Date(a.submissionRcvdDate);
                    });
                } else if (entitytype === 'TALENT') {
                    let talentDetails = response?.data?.map(item => ({ value: item.talent_id, text: item.talent_name, roles: item.roles, writerTeam: item.writer_team }));
                    formattedList = talentDetails?.filter(item => item?.roles?.includes("Writer"));
                } else if (entitytype === 'TALENT_REP') {
                    formattedList = response?.data?.map(item => ({ value: item.id, text: item.name + ' - ' + this.formatTalentRepName(item?.type), type: item.type, company: item?.company, company_id: item?.company_id }));
                }
                this.setState({ [entity]: formattedList, [loading]: false });
            },
                (err) => {
                    this.setState({ [loading]: false });
                    console.log("Error in fetching entities:", err)
                })
    }

    formatTalentRepName = (str) => {
        var i, frags = str?.split('_');
        for (i = 0; i < frags?.length; i++) {
            frags[i] = frags[i]?.charAt(0)?.toUpperCase() + frags[i]?.slice(1);
        }
        return frags?.join(' ');
    }

    getGroups = () => {
        let response = ScripterService.getData(Constants.scripterServiceBaseUrl + '/staticData?staticTable=group', null)
            .then(response => {
                let filteredSubmittedToGroups = response.data?.map(item => {
                    if (item?.name !== "Story Analyst" && item?.name !== "User Provisioner") {
                        return item?.name
                    }
                }).filter(item => item !== undefined);

                let filteredSubmittedByGroups = response.data?.map(item => {
                    if (item?.name !== "Story Analyst" && item?.name !== "Feature Story Admin") {
                        return item?.name
                    }
                }).filter(item => item !== undefined);

                this.setState({
                    submittedToGroupName: '"' + filteredSubmittedToGroups.join('","') + '"',
                    submittedByGroupName: '"' + filteredSubmittedByGroups.join('","') + '"'
                })
            },
                (err) => {
                    console.log("Error in fetching Group Details:", err)
                })
        return response
    }
    getDivisions = () => {
        ScripterService.getData(Constants.scripterServiceBaseUrl + `/entitySearch?entity=USER_DIVISIONS&searchString=`, 
            this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.id, label: item.name }));
                this.setState({ divisionDetails: formattedList }, () => {
                    this.setDefaultDivision();
                });
            },

                (err) => {
                    console.log("Error in fetching Division Details:", err)
                })
    }

    getCoverageTypes = () => {
        ScripterService.getData(Constants.scripterServiceBaseUrl + '/staticData?staticTable=lu_coverage_types',
            this.props.userContext.active_tenant.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.id, label: item.name }));
                this.setState({ coverageTypes: formattedList });
            },
                (err) => {
                    console.log("Error in fetching coverage types:", err)
                })
    }

    formatSubmissionList = (response) => {
        if (response?.akas?.length === 0) {
            response?.akas.push(JSON.parse(JSON.stringify({ ...subAka })));
        }
        if (response?.source_material?.length === 0) {
            response?.source_material.push(JSON.parse(JSON.stringify({ ...subSourceMaterial })));
        }
        if (response?.authors?.length === 0) {
            response?.authors.push(JSON.parse(JSON.stringify({ ...subAuthor })));
        }
        if (response?.elements?.length === 0) {
            response?.elements.push(JSON.parse(JSON.stringify({ ...subElement })));
        }
        if (response?.submitted_to?.length === 0) {
            response?.submitted_to.push(JSON.parse(JSON.stringify({ ...submittedTo })));
        }
        if (response?.submitted_by?.length === 0) {
            response?.submitted_by.push(JSON.parse(JSON.stringify({ ...submittedBy })));
        }
        if (response?.compare_to?.length === 0) {
            response?.compare_to.push(JSON.parse(JSON.stringify({ ...compareTo })));
        }
        if (response?.deal_memo_steps?.length === 0) {
            response?.deal_memo_steps.push(JSON.parse(JSON.stringify({ ...dealMemoSteps })));
        }
        return response;
    }


    handleGeneralEdit = (field, value) => {
        let newValue = value;
        if (field == 'project') {
            let clearedDealMemoStep = [];
            let temp = [];
            if (!value) {
                clearedDealMemoStep.push(JSON.parse(JSON.stringify({ ...dealMemoSteps })));
                if (this.props?.mode === "Edit") {
                    this.state.inActiveDealMemoSteps?.map((item) => {
                        temp.push(item);
                    });
                    this.state?.submissionDetails?.deal_memo_steps?.map((item, index) => {
                        item.is_delete = 1
                        temp.push(item);
                    })
                    this.setState({ inActiveDealMemoSteps: temp })
                }
                this.setState(prevState => ({
                    submissionDetails: {
                        ...prevState.submissionDetails,
                        delink_project_id: prevState.submissionDetails?.project_id
                    },
                }), () => {
                    this.setDefaultDivision();
                })
            }
            this.setState(prevState => ({
                submissionDetails: {
                    ...prevState.submissionDetails,
                    [field + '_id']: newValue?.value || null,
                    [field]: newValue?.text || null,
                    'division_id': newValue?.value ? null : this.state.submissionDetails?.division_id,
                    ...!(value) && { deal_memo_steps: clearedDealMemoStep }
                },
                postInitiated: false,
                project_status_id: value?.project_status_id,
                compareToPostInitiated: false,
                ...!(value) && { dealSteps: [] }

                // selectedDealStep: newValue?.value || null
            }))
        } else if (field === 'division_id') {
            this.setState(prevState => ({
                submissionDetails: {
                    ...prevState.submissionDetails,
                    [field]: newValue || null,
                    "is_division_change": 1,
                }
            }))
        } else if (field === 'is_hide_author' ||
            field === 'is_hide_title' || field === 'is_interim' ||
            field === 'is_term_deal_producer' || field === 'is_un_official'
        ) {
            this.setState(prevState => ({
                submissionDetails: {
                    ...prevState.submissionDetails,
                    [field]: newValue ? 1 : 0
                }
            }))
        } else if (field === "is_confidential") {
            //make all attached files as private
            let submissionFiles = [...this.state.submissionFiles];
            submissionFiles?.map(file => {
                if (newValue) {
                    if (file.isPrivate == 0) {
                        file.isFileUpdated = true;
                    }
                    file.isPrivate = 1;
                } else if ((!newValue && this.props?.mode === 'New') || !newValue && file.type != 'submission' || file.isFileUpdated) {
                    file.isPrivate = 0;
                }
                return file;
            })
            //check hide author & hide title
            // let hideAuthor = newValue ? 1 : 
            //     (!newValue && this.props?.mode === 'New') ? 0 : this.state.submissionDetails?.is_hide_author;
            // let hideTitle = 0;
            this.setState(prevState => ({
                submissionDetails: {
                    ...prevState.submissionDetails,
                    [field]: newValue ? 1 : 0,
                    // "is_hide_author": newValue ? 1 : 0,
                    // "is_hide_title": newValue ? 1 : 0
                },
                submissionFiles: submissionFiles
            }))
        } else if (field === "coverage_type") {
            let coverageTypeName = this.state.coverageTypes?.find(type => type.value === newValue)?.label;
            let isConfidential = 0;
            if (coverageTypeName === 'Legal' || coverageTypeName === 'Legal Addendum' ||
                coverageTypeName === 'Writing Credit Determination' || coverageTypeName === 'Legal Amendment' ||
                coverageTypeName === 'Amended Writing Credit Determination'
            ) {
                isConfidential = 1;
            } else if (this.props?.mode === 'Edit' && this.state.isConfidential) {
                isConfidential = this.state.isConfidential;
            } else if (this.props?.mode === 'Edit' && !this.state.isConfidential) {
                isConfidential = 0;
            }
            
            this.setState(prevState => ({
                submissionDetails: {
                    ...prevState.submissionDetails,
                    [field + '_id']: newValue || null,
                    [field]: coverageTypeName || null,
                    "is_confidential": isConfidential
                },
                postInitiated: false,
                compareToPostInitiated: false,
            }), () => {
                let submissionDetails = this.state.submissionDetails;
                let newDetails = [];
                if(this.props?.mode === "Edit"){
                    let editDeleteDetails = submissionDetails['compare_to']?.map(item => {
                        if (item.compare_to_id !== null) {
                            item.is_delete = 1;
                        }
                        return item
                    });
                    newDetails  = [...this.state.deletedCompareTo, ...editDeleteDetails]
                } 
                let submissionFiles = [...this.state.submissionFiles];
                submissionFiles?.map(file => {
                    if (this.state.submissionDetails?.is_confidential) {
                        if (file.isPrivate == 0) {
                            file.isFileUpdated = true;
                        }
                        file.isPrivate = 1;
                    } else if ((!this.state.submissionDetails?.is_confidential && this.props?.mode === 'New') || 
                        !this.state.submissionDetails?.is_confidential && file.type != 'submission' || file.isFileUpdated) {
                        file.isPrivate = 0;
                    }
                    return file;
                })
                this.setState(prevState => ({
                    deletedCompareTo: newDetails,
                    submissionDetails: {
                        ...prevState.submissionDetails,
                        'compare_to': [{...compareTo}],
                        // "is_hide_author": this.state.submissionDetails?.is_confidential ? 1 : 0,
                        // "is_hide_title": this.state.submissionDetails?.is_confidential ? 1 : 0
                    },
                    submissionFiles: submissionFiles
                }))
            })
        } else if (field === "due_date") {
            // if (this.props?.mode === 'New') {
                this.validateDate(newValue)
            // }
            this.setState(prevState => ({
                submissionDetails: {
                    ...prevState.submissionDetails,
                    [field]: newValue,
                },
                postInitiated: false
            }));
        } else if (field === "akas" && newValue?.length == 1) {
            if (!newValue[0]?.aka)
                newValue[0].is_delete = 1;
            else
                newValue[0].is_delete = 0;
            this.setState(prevState => ({
                submissionDetails: {
                    ...prevState.submissionDetails,
                    [field]: newValue || null
                },
                postInitiated: false
            }))
        } else {
            this.setState(prevState => ({
                submissionDetails: {
                    ...prevState.submissionDetails,
                    [field]: newValue || null
                },
                postInitiated: false
            }))
        }
    }

    validateDate = (selectedDate) => {
        let dateValid = (new Date(selectedDate) >= new Date(this.state.submissionDetails?.received_date)) 
        // new Date(selectedDate) >= new Date().setHours(0, 0, 0, 0);
        if (dateValid) {
            this.setState({ showDateError: false })
        } else {
            this.setState({ showDateError: true })
        }
        return dateValid;
    }

    formatWithUniqueEntries = () => {
        let submissionDetails = JSON.parse(JSON.stringify({ ...this.state.submissionDetails }));
        let authorLength = submissionDetails.authors.length - 1;
        // submissionDetails.akas = [...submissionDetails.akas]?.filter((tag, index, array) =>
        //     (array.findIndex(t => t.aka == tag.aka) == index) && tag.aka);
        // submissionDetails.authors = [...submissionDetails.authors]?.filter((tag, index, array) =>
        //     (array.findIndex(t => t.author_id == tag.author_id && t.author == tag.author) == index) &&
        //     tag.author_id && tag.author);
        // submissionDetails.source_material = [...submissionDetails.source_material]?.filter((tag, index, array) =>
        //     (tag.material_type_id || tag.title || tag.source_material_author_id || tag.publisher || tag.source_material_date || tag.copy_right_date)
        // )
        submissionDetails.elements = [...submissionDetails.elements]?.filter((tag, index, array) =>
            (array.findIndex(t => t.element_name == tag.element_name && t.element_role_id == tag.element_role_id &&
                t.element_status_id == tag.element_status_id) == index) && (tag.element_name));
         if(submissionDetails.authors[authorLength].author === null && submissionDetails.authors[authorLength].author_id === null) {
            submissionDetails.authors.splice(authorLength ,1);
         } 
        return submissionDetails;
    }

    validateCompareTo = (submission_compare_to, coverage_type_name) => {
        let submission_coverage_compare_to = submission_compare_to.filter(item => item.is_delete === 0)
        let isValid = false;
        switch (coverage_type_name) {
            case "Amended Writing Credit Determination":
            case "Full Project Comparison":
            case "Legal Addendum":
            case "Legal Amendment":
            case "Second Opinion":
            case "Second Opinion Project":
            case "Third Opinion":
            case "Top Sheet Comparison":
                isValid = (submission_coverage_compare_to?.length >= 1 && submission_coverage_compare_to?.some(item => item.compare_to_id != null));
                break;
            case "Extended Comparison":
                isValid = (submission_coverage_compare_to?.length >= 1 && submission_coverage_compare_to?.length <= 5 && submission_coverage_compare_to?.some(item => item.compare_to_id != null))
                if (submission_coverage_compare_to?.length > 5) {
                    this.setState({
                        showCoverageWarningGreaterThanFive: isValid === false ? true : false
                    })
                }
                break;
            case "Legal":
                isValid = (submission_coverage_compare_to?.length > 1 ? submission_coverage_compare_to?.length <= 5 && submission_coverage_compare_to?.some(item => item.compare_to_id != null) : true);
                if (submission_coverage_compare_to?.length > 5) {
                    this.setState({
                        showCoverageWarningGreaterThanFive: isValid === false ? true : false
                    })
                }
                break;
            default:
                isValid = true;
        }
        return isValid;
    }

    canSubmit = () => {
        let submissionDetails = JSON.parse(JSON.stringify({ ...this.state.submissionDetails }));
        let coverageName = submissionDetails?.coverage_type;
        // if (coverageName && (coverageName === "Amended Writing Credit Determination" || coverageName === "Extened Comparison" ||
        //     coverageName === "Legal Addendum" || coverageName === "Legal Ammendment" || coverageName === "Second Opinion" ||
        //     coverageName === "Third Opinion" || coverageName === "Top Sheet Comparison")
        // ) {
        this.setState({ compareToPostInitiated: true })
        let canSubmit =
            (submissionDetails?.submission_title) && (submissionDetails?.submission_title?.length <= limit.submissionTitle) &&
            (!submissionDetails?.akas?.some(aka => (aka.aka ? aka.aka?.length > limit.aka : false))) &&
            (submissionDetails?.company?.length ? submissionDetails?.company?.length <= limit.company : true) &&
            (submissionDetails?.coverage_type_id) && (submissionDetails?.coverage_type) &&
            (submissionDetails?.compare_to && (coverageName === "Amended Writing Credit Determination" || 
                coverageName === "Full Project Comparison" || coverageName === "Legal Addendum" || 
                coverageName === "Legal Amendment" || coverageName === "Second Opinion" || 
                coverageName === "Second Opinion Project" || coverageName === "Third Opinion" || 
                coverageName === "Top Sheet Comparison" || coverageName === "Extended Comparison" || 
                coverageName === "Legal") ? this.validateCompareTo(submissionDetails?.compare_to, coverageName) : true) &&
        (submissionDetails?.received_date) && (submissionDetails?.formats?.length > 0) &&
        // ((this.props?.mode === 'New' && submissionDetails?.due_date) ? this.validateDate(submissionDetails?.due_date) : true) &&
        (submissionDetails?.due_date ? this.validateDate(submissionDetails?.due_date) : true) &&
        (submissionDetails?.draft_version ? submissionDetails?.draft_version?.length <= limit.draftversion : true) &&
            (submissionDetails?.pgs?.length ? submissionDetails?.pgs?.length <= limit.pgs : true) &&
            (submissionDetails?.wb_pgs?.length ? submissionDetails?.wb_pgs?.length <= limit.wBPgs : true) &&
            (submissionDetails?.sku?.length ? submissionDetails?.sku?.length <= limit.sku : true) &&
            (submissionDetails?.box?.length ? submissionDetails?.box?.length <= limit.box : true) &&
            (!submissionDetails?.source_material?.some(source => (!((source.title ? source.title?.length < limit.title : true) &&
                (source.publisher ? source.publisher?.length < limit.publisher : true) &&
                (source.source_material_author ? source.source_material_author?.length < limit.author : true)) || false))) &&
            (!submissionDetails?.elements?.some(element => (!((element.element_name ? element.element_name?.length < limit.name : true)) || false))) &&
            (submissionDetails?.submission_notes ? submissionDetails?.submission_notes?.length <= sizeLimit.limitChar3000 : true);

        return canSubmit;
    }

    getFilteredJson = (submissionDetailsJson) => {
        let akaTemp = [];
        let authorsTemp = [];
        let sourceMaterialTemp = [];
        let elementsTemp = [];
        let submittedToTemp = [];
        let submittedByTemp = [];
        let compareToTemp = [];
        let dealMemoStepTemp = [];

        //AKA's Temp
        this.state.inActiveAkas?.map(item => {
            akaTemp?.push(item);
        });
        // if (submissionDetailsJson?.akas?.length == 1 && submissionDetailsJson?.akas?.[0]?.submission_aka_id === null) {
        //     submissionDetailsJson.akas = [];
        // }
        submissionDetailsJson.akas?.map(item => {
            if (!item?.aka && !item?.submission_aka_id) {
                akaTemp = akaTemp;
            } else {
                akaTemp?.push(item);
            }
        });
        submissionDetailsJson.akas = akaTemp;

        //Authors Temp
        this.state.inActiveAuthors?.map(item => {
            authorsTemp?.push(item);
        });
        submissionDetailsJson.authors?.map(item => {
            authorsTemp?.push(item);
        });
        submissionDetailsJson.authors = authorsTemp;

        //Source Materials Temp
        this.state.inActiveSourceMaterials?.map(item => {
            sourceMaterialTemp?.push(item);
        });
        submissionDetailsJson.source_material?.map(item => {
            sourceMaterialTemp?.push(item);
        });
        submissionDetailsJson.source_material = sourceMaterialTemp;

        //Elements Temp
        this.state.inActiveElements?.map(item => {
            elementsTemp?.push(item);
        });
        submissionDetailsJson.elements?.map(item => {
            elementsTemp?.push(item);
        });
        submissionDetailsJson.elements = elementsTemp;

        //Submitted To Temp
        this.state.inActiveSubmittedTo?.map(item => {
            submittedToTemp?.push(item);
        });
        submissionDetailsJson.submitted_to?.map(item => {
            submittedToTemp?.push(item);
        });
        submissionDetailsJson.submitted_to = submittedToTemp;

        //Submitted By Temp
        this.state.inActiveSubmittedBy?.map(item => {
            submittedByTemp?.push(item);
        });
        submissionDetailsJson.submitted_by?.map(item => {
            submittedByTemp?.push(item);
        });
        submissionDetailsJson.submitted_by = submittedByTemp;

        //Compare To Temp
        this.state.inActiveCompareTo?.map(item => {
            compareToTemp?.push(item);
        });
        submissionDetailsJson.compare_to?.map(item => {
            compareToTemp?.push(item);
        });
        this.state.deletedCompareTo?.map(item => {
            compareToTemp?.push(item);
        });
        submissionDetailsJson.compare_to = compareToTemp;

        //Deal Memo Step Temp
        this.state.inActiveDealMemoSteps?.map(item => {
            dealMemoStepTemp?.push(item);
        });
        submissionDetailsJson.deal_memo_steps?.map(item => {
            dealMemoStepTemp?.push(item);
        });
        submissionDetailsJson.deal_memo_steps = dealMemoStepTemp;
        let submissionData = this.state.submissionFiles?.find((item) => item?.isPrivate === 1)
        if (this.props?.mode === "Edit" && submissionData) {
            submissionDetailsJson.files = { Contents: this.state.submissionFiles, Prefix: this.state.filePrefix }
        }
        return submissionDetailsJson;
    }

    handleSubmission = () => {
        this.setState({ postInitiated: true })
        let postJson = this.formatWithUniqueEntries(this.state.submissionDetails);
        let value = postJson.deal_memo_steps?.find(item => item.deal_step === "None")?.deal_step_id
        if (value === "None") {
            postJson.deal_memo_steps = []
            postJson.deal_step_id = null;
            postJson.is_none_deal_step = 1;
            postJson.deal_step = value;
        }
        let filteredPostJson = this.getFilteredJson(postJson);
        if (this.canSubmit()) {
            this.props.setPostFlag(true);
            this.setState({ isPosting: true })
            ScripterService.postDataParams(Constants.scripterServiceBaseUrl + '/submission', filteredPostJson,
                this.props.userContext.active_tenant.tenant_id)
                .then(response => {
                    if (response.data.error) {
                        this.setState({ isPosting: false })
                        this.props.setPostFlag(false);
                        this.props.fieldChanged(true, "fail");
                    } else {
                        this.setState({ postInitiated: false, isPosting: false, compareToPostInitiated: false });
                        let submissionId = response.data[0]?.submission_id;
                        console.log(this.state);
                        this.handleFileActions(submissionId);
                        this.props.setPostFlag(false);
                        this.props.fieldChanged(true, "success");
                        this.props.toggleRefreshPage('submission');
                        if (window.location.pathname === "/") {
                            this.props.history.push("/")
                        }
                         //   this.postSubmissionWorkflow(submissionId);
                        if (this.props?.mode === "New") {
                            let submissionUrl = '/submissionDetails/' + submissionId
                            this.props.history.push(submissionUrl)
                            // window.open(submissionUrl)
                        }
                    }
                },
                    (err) => {
                        console.log("Error in posting submission:", err)
                        this.setState({ postInitiated: false, isPosting: false, compareToPostInitiated: false });
                        this.props.setPostFlag(false);
                        this.props.fieldChanged(true, "fail");
                    })
        } else {
            console.log("Submission Failed")
        }
    }

    handleFileActions = async (submissionId) => {
        let promises = [];
        if (this.state.submissionFiles.length > 0) {
            promises = await this.state.submissionFiles?.map(file => 
          {
            if(this.state?.project_status_id && !file?.key && file?.size){
                file['isPrivate'] = this.state?.project_status_id && projectStatusesMakePrivate?.includes(this.state?.project_status_id) && 1;
            }
            this.fileRef.current.onFileUpload(file, submissionId);
            //this.setState({project_status_id:null})
           }
        )
        }
        if (this.props?.mode === "Edit" && this.state.isDeleteFile && this.state.deletedFileList.length > 0) {
            let a = await this.state.deletedFileList?.map((file) => (file?.url ? this.fileRef.current.deleteFile(file.name) : null
            ))
            promises = [...promises, ...a]
        }
        await Promise.allSettled(promises).then(async (results) => await setTimeout(() => {
            this.props.toggleRefreshPage('submission');
        }, 1000));
    }


    // postSubmissionWorkflow = (submissionId) => {
    //     let postJson = {...submissionWorkflowMockData};
    //     let subStatus = {
    //         'Created': 'CREATE',
    //         'Sent to Story Department': 'SUBMIT',
    //         'Confirmed': 'CONFIRM SUBMISSION',
    //         'Return to Story Department': 'RETURN TO STORY DEPARTMENT',
    //         'Return for Revision': 'REVISE SUBMISSION'
    //     }
    //     postJson.submission_id = submissionId;
    //     postJson.action_label = subStatus[this.state.submissionDetails?.submission_status] ?? "CREATE";
    //     postJson.created_at = new Date().toLocaleString('en-US', { hour12: false});

    //     ScripterService.postDataParams(Constants.scripterServiceBaseUrl + '/submissionCoverageWorkflow', postJson,
    //         this.props.userContext.active_tenant.tenant_id, this.props.userContext.active_tenant.division_id)
    //         .then(response => {
    //             console.log("response", response)
    //         },
    //             (err) => {
    //                 console.log("Error in confirming submission:", err)
    //             })
    // }

    handleListEdit = (field, value, obj, listName) => {
        let submissionDetails = JSON.parse(JSON.stringify({ ...this.state.submissionDetails }));
        let id = (listName === "authors") ? "submission_author_id" :
            (listName === "akas") ? "submission_aka_id" :
                (listName === "elements") ? "submission_element_id" :
                    (listName === "source_material") ? "submission_material_id" :
                        (listName === "submitted_to") ? "submission_submitted_to_id" :
                            (listName === "submitted_by") ? "submission_submitted_by_id" :
                                (listName === "compare_to") ? "submission_compare_to_id" : "";
        let list = submissionDetails[listName]?.map(item => {
            let currentVal = item;
            if ((!obj[id] && item.index && obj.index && (item.index === obj.index)) || (item[id] && obj[id] && (item[id] === obj[id]))) {
                currentVal[field] = value;
                if (field === 'name') {
                    currentVal.element_name = value?.text
                    currentVal.talent_id = !value?.writerTeam ? value?.value : null
                    currentVal.talent_group_id = value?.writerTeam ? value?.value : null
                    currentVal.writerTeam = value?.writerTeam
                    currentVal.talent_selected = value
                }
                else if (field === 'element_role_id') {
                    currentVal.element_role = this.state.roles?.find(role => role.value === value)?.label;
                } else if (field === 'element_status_id') {
                    currentVal.element_status = this.state.statuses?.find(status => status.value === value)?.label;
                } else if (field === 'author_id') {
                    currentVal.author = value?.text;
                    currentVal.author_id = !value?.writerTeam ? value?.value : null;
                    currentVal['talent_group_id'] = value?.writerTeam ? value?.value : null;
                } else if (field === 'material_type_id') {
                    currentVal.material_type = value?.text;
                    currentVal.material_type_id = value?.value;
                } else if (field === 'source_material_author_id') {
                    currentVal.source_material_author = value?.text;
                    currentVal.source_material_author_id = !value?.writerTeam ? value?.value : null;
                    currentVal['source_material_talent_group_id'] = value?.writerTeam ? value?.value : null;
                } else if (field === 'submitted_to_id') {
                    currentVal.submitted_to = value?.text;
                    currentVal.submitted_to_id = value?.value;
                } else if (field === 'submitted_by_id') {
                    currentVal.talent_id = value?.type === 'talent' ? value?.value : null;
                    currentVal.talent_group_id = value?.type === 'talent_group' ? value?.value : null;
                    currentVal.representative_id = value?.type === 'representative' ? value?.value : null;
                    currentVal.submitted_by = value?.text || null;
                    currentVal.loanout_company = value?.company || null;
                    currentVal.agency_id = (value?.type === 'representative' && value?.company_id) ? value?.company_id : null;
                    currentVal['is_company_change'] = 0;
                } else if (field === 'loanout_company') {
                    currentVal.loanout_company = value;
                    currentVal['is_company_change'] = value ? 1 : 0;
                } else if (field === 'agency_id') {
                    currentVal.agency_id = value?.value || null;
                    currentVal.loanout_company = value?.text || null;
                    currentVal['is_company_change'] = value?.value ? 1 : 0;
                } else if (field === 'compare_to_id') {
                    currentVal.compare_to = value?.text;
                    currentVal.compare_to_id = value?.value;
                }
                return currentVal;
            }
            return currentVal;
        })
        this.handleGeneralEdit(listName, list);
    }

    addAdditionalField = (listName) => {
        this.setState({ postInitiated: false, compareToPostInitiated: false });
        let submissionDetails = JSON.parse(JSON.stringify({ ...this.state.submissionDetails }));
        switch (listName) {
            case 'akas':
                let initialAkaArray = JSON.parse(JSON.stringify({ ...subAka }));
                let akas = [...submissionDetails.akas];
                initialAkaArray.index = this.state.akaIndex;
                initialAkaArray.canRemove = true;
                akas.push(initialAkaArray)
                submissionDetails.akas = akas;
                this.setState({ akaIndex: this.state.akaIndex + 1, submissionDetails: submissionDetails });
                break;
            case 'authors':
                let initialAuthorArray = JSON.parse(JSON.stringify({ ...subAuthor }));
                let authors = [...submissionDetails.authors];
                initialAuthorArray.index = this.state.authorIndex;
                initialAuthorArray.canRemove = true;
                authors.push(initialAuthorArray)
                submissionDetails.authors = authors;
                this.setState({ authorIndex: this.state.authorIndex + 1, submissionDetails: submissionDetails });
                break;
            case 'elements':
                let initialElementArray = JSON.parse(JSON.stringify({ ...subElement }));
                let elements = [...submissionDetails.elements];
                initialElementArray.index = this.state.elementIndex;
                initialElementArray.canRemove = true;
                elements.push(initialElementArray)
                submissionDetails.elements = elements;
                this.setState({ elementIndex: this.state.elementIndex + 1, submissionDetails: submissionDetails });
                break;
            case 'source_material':
                let initialSourceArray = JSON.parse(JSON.stringify({ ...subSourceMaterial }));
                let source_material = [...submissionDetails.source_material];
                initialSourceArray.index = this.state.sourceIndex;
                initialSourceArray.canRemove = true;
                source_material.push(initialSourceArray)
                submissionDetails.source_material = source_material;
                this.setState({ sourceIndex: this.state.sourceIndex + 1, submissionDetails: submissionDetails });
                break;
            case 'submitted_to':
                let initialSubmittedToArray = JSON.parse(JSON.stringify({ ...submittedTo }));
                let submitted_to = [...submissionDetails.submitted_to];
                initialSubmittedToArray.index = this.state.submittedToIndex;
                initialSubmittedToArray.canRemove = true;
                submitted_to.push(initialSubmittedToArray)
                submissionDetails.submitted_to = submitted_to;
                this.setState({ submittedToIndex: this.state.submittedToIndex + 1, submissionDetails: submissionDetails });
                break;
            case 'submitted_by':
                let initialSubmittedByArray = JSON.parse(JSON.stringify({ ...submittedBy }));
                let submitted_by = [...submissionDetails.submitted_by];
                initialSubmittedByArray.index = this.state.submittedByIndex;
                initialSubmittedByArray.canRemove = true;
                submitted_by.push(initialSubmittedByArray)
                submissionDetails.submitted_by = submitted_by;
                this.setState({ submittedByIndex: this.state.submittedByIndex + 1, submissionDetails: submissionDetails });
                break;
            case 'compare_to':
                let initialCompareToArray = JSON.parse(JSON.stringify({ ...compareTo }));
                let compare_to = [...submissionDetails.compare_to];
                initialCompareToArray.index = this.state.compareToIndex;
                initialCompareToArray.canRemove = true;
                compare_to.push(initialCompareToArray)
                submissionDetails.compare_to = compare_to;
                this.setState({ compareToIndex: this.state.compareToIndex + 1, submissionDetails: submissionDetails });
                break;
            case 'deal_memo_steps':
                let initialDealMemoStepsArray = JSON.parse(JSON.stringify({ ...dealMemoSteps }));
                let deal_memo_steps = [...submissionDetails.deal_memo_steps];
                initialDealMemoStepsArray.index = this.state.dealMemoStepIndex;
                initialDealMemoStepsArray.canRemove = true;
                deal_memo_steps.push(initialDealMemoStepsArray)
                submissionDetails.deal_memo_steps = deal_memo_steps;
                this.setState({
                    dealMemoStepIndex: this.state.dealMemoStepIndex + 1,
                    submissionDetails: submissionDetails,
                    dealSteps: []
                });
                break;
        }
    }

    // removeDealSteps = (updatedDealStepList) => {
    //     let submissionDetails = { ...this.state.submissionDetails }
    //     let temp = [];
    //     this.state.inActiveDealMemoSteps?.map(item => {
    //         temp.push(item);
    //     })
    //     let removedItem = submissionDetails.deal_memo_steps.filter(o1 => !updatedDealStepList.some(o2 => o1.deal_step_id === o2.deal_step_id));
    //     removedItem[0]['is_delete'] = 1;
    //     temp.push(removedItem[0])
    //     this.setState({ inActiveDealMemoSteps: temp })
    //     this.handleGeneralEdit('deal_memo_steps', updatedDealStepList)
    // }

    removeField = (listName, callbackItem, index) => {
        this.setState({
            formEdited: true,
            listName: listName,
            callbackItem: callbackItem,
            postInitiated: false,
            compareToPostInitiated: false,
            index: index
        }, () => {
            switch (listName) {
                case 'akas':
                    if (callbackItem.submission_aka_id) {
                        this.showMessage();
                    } else {
                        this.deleteRow(listName, callbackItem, 'new');
                    }
                    break;
                case 'authors':
                    if (callbackItem.submission_author_id) {
                        this.showMessage();
                    } else {
                        this.deleteRow(listName, callbackItem, 'new');
                    }
                    break;
                case 'source_material':
                    if (callbackItem.submission_material_id) {
                        this.showMessage();
                    } else {
                        this.deleteRow(listName, callbackItem, 'new');
                    }
                    break;
                case 'elements':
                    if (callbackItem.submission_element_id) {
                        this.showMessage();
                    } else {
                        this.deleteRow(listName, callbackItem, 'new');
                    }
                    break;
                case 'submitted_to':
                    if (callbackItem.submission_submitted_to_id) {
                        this.showMessage();
                    } else {
                        this.deleteRow(listName, callbackItem, 'new');
                    }
                    break;
                case 'submitted_by':
                    if (callbackItem.submission_submitted_by_id) {
                        this.showMessage();
                    } else {
                        this.deleteRow(listName, callbackItem, 'new');
                    }
                    break;
                case 'compare_to':
                    if (callbackItem.submission_compare_to_id) {
                        this.showMessage();
                    } else {
                        this.deleteRow(listName, callbackItem, 'new');
                    }
                    break;
                case 'deal_memo_steps':
                    if (callbackItem.deal_step_id) {
                        this.showMessage();
                    } else {
                        this.deleteRow(listName, callbackItem, 'new');
                    }
                    break;
            }
        })
    }

    showMessage = () => {
        this.setState({ isOpen: true })
    }

    deleteRow = (listName, callbackItem, type, index = null) => {
        let submissionDetails = this.state.submissionDetails;
        switch (listName) {
            case 'akas':
                submissionDetails[listName][this.state.index].is_delete = 1;
                if (type === 'edit') {
                    let temp = [];
                    this.state.inActiveAkas?.map((item) => {
                        temp.push(item);
                    });
                    let aka_list = submissionDetails[listName].filter(item =>
                        (item.is_delete === 1 && item.index === callbackItem.index)
                    );
                    temp.push(aka_list[0]);
                    this.setState({ inActiveAkas: temp })
                }
                let aka_list = submissionDetails[listName].filter(item =>
                    (item.submission_aka_id !== callbackItem.submission_aka_id) || (item.index !== callbackItem.index)
                );
                if (aka_list.length === 0) {
                    submissionDetails[listName] = [{ ...subAka }];
                } else {
                    submissionDetails[listName] = aka_list;
                }
                break;

            case 'authors':
                submissionDetails[listName][this.state.index].is_delete = 1;
                if (type === 'edit') {
                    let temp = [];
                    this.state.inActiveAuthors?.map((item) => {
                        temp.push(item);
                    });
                    let authors_list = submissionDetails[listName].filter(item =>
                        (item.is_delete === 1 && item.index === callbackItem.index)
                    );
                    temp.push(authors_list[0]);
                    this.setState({ inActiveAuthors: temp })
                }
                let authors_list = submissionDetails[listName].filter(item =>
                    (item.submission_author_id !== callbackItem.submission_author_id) || (item.index !== callbackItem.index)
                );
                if (authors_list.length === 0) {
                    submissionDetails[listName] = [{ ...subAuthor }];
                } else {
                    submissionDetails[listName] = authors_list;
                }
                break;

            case 'source_material':
                submissionDetails[listName][this.state.index].is_delete = 1;
                if (type === 'edit') {
                    let temp = [];
                    this.state.inActiveSourceMaterials?.map((item) => {
                        temp.push(item);
                    });
                    let source_material_list = submissionDetails[listName].filter(item =>
                        (item.is_delete === 1 && item.index === callbackItem.index)
                    );
                    temp.push(source_material_list[0]);
                    this.setState({ inActiveSourceMaterials: temp })
                }
                let source_material_list = submissionDetails[listName].filter(item =>
                    (item.submission_material_id !== callbackItem.submission_material_id) || (item.index !== callbackItem.index)
                );
                if (source_material_list.length === 0) {
                    submissionDetails[listName] = [{ ...subSourceMaterial }];
                } else {
                    submissionDetails[listName] = source_material_list;
                }
                break;

            case 'elements':
                submissionDetails[listName][this.state.index].is_delete = 1;
                if (type === 'edit') {
                    let temp = [];
                    this.state.inActiveElements?.map((item) => {
                        temp.push(item);
                    });
                    let element_list = submissionDetails[listName].filter(item =>
                        (item.is_delete === 1 && item.index === callbackItem.index)
                    );
                    temp.push(element_list[0]);
                    this.setState({ inActiveElements: temp })
                }
                let element_list = submissionDetails[listName].filter(item =>
                    (item.submission_element_id !== callbackItem.submission_element_id) || (item.index !== callbackItem.index)
                );
                if (element_list.length === 0) {
                    submissionDetails[listName] = [{ ...subElement }];
                } else {
                    submissionDetails[listName] = element_list;
                }
                break;

            case 'submitted_to':
                submissionDetails[listName][this.state.index].is_delete = 1;
                if (type === 'edit') {
                    let temp = [];
                    this.state.inActiveSubmittedTo?.map((item) => {
                        temp.push(item);
                    });
                    let submitted_to_list = submissionDetails[listName].filter(item =>
                        (item.is_delete === 1 && item.index === callbackItem.index)
                    );
                    temp.push(submitted_to_list[0]);
                    this.setState({ inActiveSubmittedTo: temp })
                }
                let submitted_to_list = submissionDetails[listName].filter(item =>
                    (item.submission_submitted_to_id !== callbackItem.submission_submitted_to_id) || (item.index !== callbackItem.index)
                );
                if (submitted_to_list.length === 0) {
                    submissionDetails[listName] = [{ ...submittedTo }];
                } else {
                    submissionDetails[listName] = submitted_to_list;
                }
                break;

            case 'submitted_by':
                submissionDetails[listName][this.state.index].is_delete = 1;
                if (type === 'edit') {
                    let temp = [];
                    this.state.inActiveSubmittedBy?.map((item) => {
                        temp.push(item);
                    });
                    let submitted_by_list = submissionDetails[listName].filter(item =>
                        (item.is_delete === 1 && item.index === callbackItem.index)
                    );
                    temp.push(submitted_by_list[0]);
                    this.setState({ inActiveSubmittedBy: temp })
                }
                let submitted_by_list = submissionDetails[listName].filter(item =>
                    (item.submission_submitted_by_id !== callbackItem.submission_submitted_by_id) || (item.index !== callbackItem.index)
                );
                if (submitted_by_list.length === 0) {
                    submissionDetails[listName] = [{ ...submittedBy }];
                } else {
                    submissionDetails[listName] = submitted_by_list;
                }
                break;
            case 'compare_to':
                submissionDetails[listName][this.state.index].is_delete = 1;
                if (type === 'edit') {
                    let temp = [];
                    this.state.inActiveCompareTo?.map((item) => {
                        temp.push(item);
                    });
                    let compare_to_list = submissionDetails[listName].filter(item =>
                        (item.is_delete === 1 && item.index === callbackItem.index)
                    );
                    temp.push(compare_to_list[0]);
                    this.setState({ inActiveCompareTo: temp })
                }
                let compare_to_list = submissionDetails[listName].filter(item =>
                    (item.submission_compare_to_id !== callbackItem.submission_compare_to_id) || (item.index !== callbackItem.index)
                );
                if (compare_to_list.length === 0) {
                    submissionDetails[listName] = [{ ...compareTo }];
                } else {
                    submissionDetails[listName] = compare_to_list;
                }
                break;
            case 'deal_memo_steps':
                let currIndex = (index != null) ? index : this.state.index
                submissionDetails[listName][currIndex].is_delete = 1;
                if (type === 'edit') {
                    let temp = [];
                    this.state.inActiveDealMemoSteps?.map((item) => {
                        temp.push(item);
                    });
                    let deal_memo_steps_list = submissionDetails[listName].filter(item =>
                        (item.is_delete === 1 && item.index === callbackItem.index)
                    );
                    temp.push(deal_memo_steps_list[0]);
                    this.setState({ inActiveDealMemoSteps: temp })
                }
                let deal_memo_steps_list = submissionDetails[listName].filter(item =>
                    (item.deal_step_id !== callbackItem.deal_step_id) || (item.index !== callbackItem.index)
                );
                if (deal_memo_steps_list.length === 0) {
                    submissionDetails[listName] = [{ ...dealMemoSteps }];
                } else {
                    submissionDetails[listName] = [...deal_memo_steps_list];
                }
                break;
        }
        this.setState({
            submissionDetails: submissionDetails,
            listName: null,
            callbackItem: null,
            postInitiated: false,
            compareToPostInitiated: false,
            isOpen: false,
            showCoverageWarningGreaterThanFive: false
        });
    }

    handleFileUpload = (file, attachedFiles) => {
        this.setState({
            attachedFile: file,
            submissionFiles: [...this.state.submissionFiles, ...attachedFiles]
        })
    }

    handleFileDelete = (file, isDeleteFile) => {
        let fileList = this.state.submissionFiles;
        const fileIndex = fileList?.indexOf(file);
        if (fileIndex > -1) {
            fileList?.splice(fileIndex, 1);
        }
        this.setState({
            isDeleteFile: isDeleteFile,
            submissionFiles: fileList,
            deletedFileList: [...this.state.deletedFileList, file]
        })
    }
    handleFilePrivate = (file, isPrivate, index) => {
        // if ((isPrivate == 0 && isPrivate == 1) && (this.props?.userContext?.user_profile?.group_name != 'Feature Story Admin' ||
        //     this.props?.userContext?.user_profile?.user_id != this.state.submissionDetails?.created_by)
        // ) {
        //     return;
        // }
        let fileList = [...this.state.submissionFiles];
        const fileIndex = fileList?.indexOf(file);
        if (fileIndex > -1) {
            fileList?.map((item, fIndex) => {
                if (item.name === file?.name && fIndex === index) {
                    return item.isPrivate = isPrivate
                }
            })
        }
        this.setState({
            isPrivateFile: isPrivate,
            submissionFiles: fileList,
            isPrivateFileList: [...this.state.isPrivateFileList, file]
        })
    }

    render() {
        let isLoading = this.state.isFetchingSubmissionDetails || this.state.isFetchingCoverageTypes || this.state.isFetchingFormats ||
            this.state.isFetchingTypes || this.state.isFetchingStatuses || this.state.isFetchingRoles;

        return (
            <>
                {isLoading ? (
                    <div className="ContentLoader">
                        <CircularProgress />
                    </div>
                ) : (
                    <MDBContainer fluid className="submission-container">
                        <MessageModal
                            open={this.state.isOpen}
                            title={"Delete record"}
                            message={"Would you like to delete saved record?"}
                            primaryButtonText={"Delete"}
                            secondaryButtonText={"Cancel"}
                            onConfirm={() => this.deleteRow(this.state.listName, this.state.callbackItem, 'edit')}
                            handleClose={(e) => this.setState({ isOpen: false })} />
                        <MDBRow>
                            <SubGeneral
                                subGeneralRef={this.subGeneralRef}
                                coverageTypes={this.state.coverageTypes || []}
                                submissionDetails={this.state.submissionDetails || {}}
                                postInitiated={this.state.postInitiated || false}
                                compareToPostInitiated={this.state.compareToPostInitiated || false}
                                handleGeneralEdit={this.handleGeneralEdit}
                                handleListEdit={this.handleListEdit}
                                addCallback={this.addAdditionalField}
                                removeCallback={this.removeField}
                                getEntity={this.getEntity}
                                submittedToList={this.state.submittedToList}
                                submittedByList={this.state.submittedByList}
                                projectList={this.state.projectList}
                                dealSteps={this.state.dealSteps}
                                submissionList={this.state.submissionList}
                                handleClose={this.props?.handleClose}
                                selectedDealStep={this.state.selectedDealStep}
                                linkedDealSteps={this.state.linkedDealSteps}
                                formatTalentRepName={this.formatTalentRepName}
                                removeDealSteps={this.removeDealSteps}
                                navFuns={this.props?.navFuns}
                                mode={this.props?.mode}
                                fileRef={this.fileRef}
                                toggleRefreshPage={() => this.props.toggleRefreshPage('submission')}
                                attachedModuleFiles={this.state.submissionFiles}
                                handleFileUpload={this.handleFileUpload}
                                handleFileDelete={this.handleFileDelete}
                                handleFilePrivate={this.handleFilePrivate}
                                getTalentName={this.getTalentName}
                                filePayload={{
                                    ...filePayload,
                                    submissionId: this.props?.submissionId,
                                    type: "submission",
                                    module: 'submission',
                                    subModule: 'misc',
                                }}
                                showDateError={this.state.showDateError}
                                formats={this.state.formats || []}
                                userList={this.state.talentList}
                                divisionDetails={this.state.divisionDetails}
                                showCoverageWarningGreaterThanFive={this.state.showCoverageWarningGreaterThanFive}
                                loading={this.state.isSubmissionLoading}
                            />
                            <BasicLabel type={"darkFieldHeading"} text={"Based On Material"} />
                            {
                                this.state.submissionDetails?.source_material?.map((source, index) => (
                                    (source.is_delete === 0 ?
                                        <SubSourceMaterials
                                            types={this.state.types || []}
                                            postInitiated={this.state.postInitiated || false}
                                            source={source || {}}
                                            handleGeneralEdit={this.handleGeneralEdit}
                                            showAdd={(this.state.submissionDetails?.source_material.length - 1 === index && source.title?.length > 0)}
                                            showRemove={!(this.state.submissionDetails?.source_material.length === 1) ? true : false}
                                            addCallback={() => this.addAdditionalField('source_material')}
                                            removeCallback={() => this.removeField('source_material', source, index)}
                                            onChange={this.handleListEdit}
                                            getEntity={this.getEntity}
                                            userList={this.state.talentList}
                                        /> : null
                                    )
                                ))
                            }
                            <BasicLabel type={"darkFieldHeading"} text={"Elements"} />
                            <div className="submission-elements submission-row-margin">
                                {
                                    this.state.submissionDetails?.elements?.map((element, index) => (
                                        (element.is_delete === 0 ?
                                            <MDBRow className="pr-0">
                                                <MDBCol md={11} className="pr-0">
                                                    <EditElements
                                                        index={index}
                                                        statuses={this.state.statuses || []}
                                                        getTalents={this.getTalents}
                                                        selectedTalent={this.selectedTalent}
                                                        roles={this.state.roles || []}
                                                        element={element || {}}
                                                        onChange={this.handleListEdit}
                                                    />
                                                </MDBCol>
                                                <MDBCol md={1} className="p-0">
                                                    <MDBRow className={index === 0 ? "actionButons ml-1" : "ml-1"}>
                                                        <MDBCol md={1} className="actions p-0">
                                                            {
                                                                !(this.state.submissionDetails?.elements.length === 1) ?
                                                                    <MDBIcon
                                                                        icon="trash-alt"
                                                                        size='sm'
                                                                        onClick={(e) => this.removeField('elements', element, index)}
                                                                    />
                                                                    : null
                                                            }
                                                        </MDBCol>
                                                        <MDBCol md={1} className="actions">
                                                            {
                                                                (this.state.submissionDetails?.elements.length - 1 === index && element.element_name?.length > 0) ?
                                                                    <AddCircleIcon fontSize="small"
                                                                        onClick={(e) =>
                                                                            this.addAdditionalField('elements', index)
                                                                        } /> : null
                                                            }
                                                        </MDBCol>
                                                    </MDBRow>
                                                </MDBCol>
                                            </MDBRow>
                                            : null
                                        )
                                    ))
                                }
                            </div>
                        </MDBRow>
                        <MDBRow className="submission-row-margin">
                            <MDBCol md={5}>
                                <MDBRow>
                                    <BasicCheckBox
                                        label="Unofficial"
                                        id={"unofficial"}
                                        checked={this.state.submissionDetails?.is_un_official}
                                        onChange={(e) => this.handleGeneralEdit("is_un_official", e.target.checked)}
                                    />
                                </MDBRow>
                                <MDBRow>
                                    <BasicCheckBox
                                        label="Interim"
                                        id={"interim"}
                                        checked={this.state.submissionDetails?.is_interim}
                                        onChange={(e) => this.handleGeneralEdit("is_interim", e.target.checked)}
                                    />
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md={4} className="term-deal-prod">
                                        <BasicCheckBox
                                            label="Term Deal Producer"
                                            id={"term-deal-producer"}
                                            checked={this.state.submissionDetails?.is_term_deal_producer}
                                            onChange={(e) => this.handleGeneralEdit("is_term_deal_producer", e.target.checked)}
                                        />
                                    </MDBCol>
                                    <MDBCol md={6} className="term-deal-input">
                                        {this.state.submissionDetails?.is_term_deal_producer ?
                                            <BasicTextField
                                                id="term-deal-company"
                                                // label={"Term Deal Company"}
                                                width={'100%'}
                                                value={this.state.submissionDetails?.term_deal_company || ""}
                                                onChange={(e) => this.handleGeneralEdit('term_deal_company', e.target.value)}
                                            />
                                            : null
                                        }
                                    </MDBCol>
                                </MDBRow>

                            </MDBCol>

                            <MDBCol md={3}>
                                <MDBRow>
                                    <BasicCheckBox
                                        label="Confidential"
                                        id={"confidential"}
                                        checked={this.state.submissionDetails?.is_confidential}
                                        onChange={(e) => this.handleGeneralEdit("is_confidential", e.target.checked)}
                                        disabled={
                                            (this.state.submissionDetails?.coverage_type === 'Legal' ||
                                                this.state.submissionDetails?.coverage_type === 'Legal Addendum' ||
                                                this.state.submissionDetails?.coverage_type === 'Legal Amendment' ||
                                                this.state.submissionDetails?.coverage_type === 'Writing Credit Determination' ||
                                                this.state.submissionDetails?.coverage_type === 'Amended Writing Credit Determination'
                                            ) ? true : false
                                        }
                                    />
                                </MDBRow>
                                {this.state.submissionDetails?.is_confidential ?
                                    <>
                                        <MDBRow>
                                            <BasicCheckBox
                                                label="Hide Author (on Coverage)"
                                                id={"hide-author"}
                                                checked={this.state.submissionDetails?.is_hide_author}
                                                onChange={(e) => this.handleGeneralEdit("is_hide_author", e.target.checked)}
                                            />
                                        </MDBRow>
                                        <MDBRow>
                                            <BasicCheckBox
                                                label="Hide Title (on Coverage)"
                                                id={"hide-title"}
                                                onChange={(e) => this.handleGeneralEdit("is_hide_title", e.target.checked)}
                                                checked={this.state.submissionDetails?.is_hide_title}
                                            />
                                        </MDBRow> 
                                    </> : null
                                }
                            </MDBCol>
                            <MDBCol md={2}>
                                <MDBRow>
                                    <BasicTextField
                                        id="box"
                                        label={"Box #"}
                                        limit={limit.box}
                                        limitWarning={messages.exceed20CharacterWarning || ""}
                                        value={this.state.submissionDetails?.box || ""}
                                        onChange={(e) =>
                                            validateCharNumOnly(e.target.value) ? this.handleGeneralEdit('box', e.target.value) : null}
                                    />
                                </MDBRow>
                                <MDBRow>
                                    <BasicTextField
                                        id="sku"
                                        label={"SKU #"}
                                        limit={limit.sku}
                                        limitWarning={messages.exceed20CharacterWarning || ""}
                                        value={this.state.submissionDetails?.sku || ""}
                                        onChange={(e) =>
                                            validateCharNumOnly(e.target.value) ? this.handleGeneralEdit('sku', e.target.value) : null}
                                    />
                                </MDBRow>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <BasicTextArea
                                label="Submission Notes"
                                limit={sizeLimit.limitChar3000}
                                limitWarning={messages.exceed3000CharacterWarning || ""}
                                value={this.state.submissionDetails?.submission_notes || ""}
                                onChange={(e) => this.handleGeneralEdit('submission_notes', e.target.value)}
                                typedCharCount={this.state.submissionDetails?.submission_notes?.length || 0}
                                showCharCount={true}
                            />
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={10}></MDBCol>
                            <MDBCol md={2} className="save-actions">
                                <MDBRow className="mt-1">
                                    <MDBCol className="create-btn">
                                        <BasicButton
                                            text={this.state.isPosting ? <CircularProgress color="inherit" size={18} /> :
                                                this.props.mode === "New" ? "Create" : "Save"}
                                            onClick={() => this.handleSubmission()}
                                        />
                                    </MDBCol>
                                    <MDBCol className="cancel-btn">
                                        <BasicButton
                                            variant="outlined"
                                            text={"Cancel"}
                                            onClick={this.props?.handleClose} />
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                )
                }
            </>
        );
    }
}))

