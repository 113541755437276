import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { MDBCard, MDBCardBody, MDBCardText } from 'mdb-react-ui-kit';
import CircularProgress from "@material-ui/core/CircularProgress";
import BasicButton from '../../../SharedComponents/BasicButton/BasicButton';
import BasicLabel from '../../../SharedComponents/BasicLabel/BasicLabel';
import { coverage, coverageInitialDetails, submissionWorkflowMockData } from './Config';
import MainViewModalComponent from '../../../../Common/MainViewModalLayout/Modal/Modal';
import { getFormattedDate } from '../../../../Common/Helper';
// import NotificationComponent from '../../../../Common/NotificationComponent/NotificationComponent';
import MessageModal from '../../../SharedComponents/MessageModal';
import ScripterService from '../../../../services/service';
import * as Constants from '../../../../constants/constants';
import { withUserContext } from '../../../../contexts/UserContext';

class SubmissionDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabValue: 1,
            coverageDetails: coverageInitialDetails,
            coverageIndex: 2,
            showReviseSubmissionModal: false,
            showReturnRevisionButton: true,
            showCancelSubmissionModal: false,
            showConfirmSubmissionModal: false,
            openNotification: false,
            notificationMessage: "",
            severity: "success",
            showRecordCannotbeDeleted: false
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps?.submissionDetails?.submission_notes != this.props?.submissionDetails?.submission_notes) {
            document.querySelectorAll('.notes').forEach(function(tag) {
                var inner = tag.innerHTML;
                inner = inner.replace(/((http|https|ftp|ftps)\:\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(\/\S*)?)/g, '<a href="$1" target="_blank">$1</a>')
                tag.innerHTML = inner;
            });
        }
    }

    addAssignCoverage = () => {
        let coverageDetails = { ...this.state.coverageDetails };
        let initialCoverageList = { ...coverage };
        let coverage_list = [...coverageDetails.coverage_list];
        initialCoverageList.index = this.state.coverageIndex;
        initialCoverageList.canRemove = true;
        coverage_list.push(initialCoverageList)
        coverageDetails.coverage_list = coverage_list;
        this.setState({ coverageIndex: this.state.coverageIndex + 1, coverageDetails: coverageDetails });
    }

    getCommaSeparatedValues = (field, list) => {
        let formattedValue = "";
        if (typeof list === 'string') {
            formattedValue = list;
        } else {
            list?.map(item => item[field] ? (formattedValue = formattedValue + (formattedValue ? ", " : " ") + item[field]) : null);
        }
        return formattedValue;
    }

    getSubmittedToNames = (submittedToList) => {
        let submittedToValues = [];
        submittedToList?.map(item => {
            submittedToValues?.push(item.submitted_to)
        })
        return submittedToValues?.join(', ');
    }

    getSubmittedByNames = (submittedByList) => {
        let submittedByValues = [];
        submittedByList?.map(item => {
            let submittedByName = item?.talent_id ? item?.talent_name :
                item?.talent_group_id ? item?.talent_group_name :
                    item?.representative_id ? item?.representative_name : null;
            let submittedByCompanyLabel = submittedByName ?
                (submittedByName + (item?.loanout_company ? (', ' + item?.loanout_company) : '')) : null;
            if (submittedByCompanyLabel) {
                submittedByValues?.push(submittedByCompanyLabel)
            }
        })
        return submittedByValues?.join('\n');
    }

    handlePageRefresh = (page) => {
        if (page === 'submission') {
            this.props?.toggleRefreshPage('submission')
        }
    }

    handleConfirmSubmission = () => {
        this.setState({ isPosting: true })
        let postJson = this.getPostJsonData("CONFIRM SUBMISSION");
        this.postWorkflowSubmission(postJson, 'showConfirmSubmissionModal', 'confirm_submission');
    }

    cancelSubmission = () => {
        this.setState({ isPosting: true })
        let postJson = this.getPostJsonData("CANCEL SUBMISSION");
        this.postWorkflowSubmission(postJson, 'showCancelSubmissionModal', 'cancel_submission');
    }

    handleSubmitSubmission = () => {
        this.setState({ isSubmitPosting: true })
        let postJson = this.getPostJsonData("SUBMIT");
        this.postWorkflowSubmission(postJson, null, 'submit');
    }

    getPostJsonData = (actionLabel) => {
        let postJson = {...submissionWorkflowMockData};
        postJson.submission_id = this.props?.submissionId;
        postJson.submission_coverage_id = null;
        postJson.action_label = actionLabel;
        postJson.created_at = new Date().toLocaleString('en-US', { hour12: false});

        return postJson
    }

    postWorkflowSubmission = (postJson, modal, actionLabel) => {
        ScripterService.postDataParams(Constants.scripterServiceBaseUrl + '/submissionCoverageWorkflow', postJson,
            this.props.userContext.active_tenant.tenant_id, this.props?.submissionDetails?.division_id)
            .then(response => {
                if (response.data.error) {
                    this.setState({ isPosting: false, [modal]: false, isSubmitPosting: false })
                    this.props?.manageMessageModal('Fail', true);
                } else {
                    if (actionLabel == 'cancel_submission') {
                        if (response.data && response.data.length > 0) {
                            this.setState({ isPosting: false, [modal]: false, isSubmitPosting: false, showRecordCannotbeDeleted: true })
                        } else {
                            this.setState({ isPosting: false, [modal]: false, isSubmitPosting: false });
                            this.props?.manageMessageModal('Success', true, true, actionLabel);
                        }
                    } else {
                        this.setState({ isPosting: false, [modal]: false, isSubmitPosting: false });
                        this.props?.manageMessageModal('Success', true, true, actionLabel);
                    }
                }
            },
                (err) => {
                    console.log("Error in confirming submission:", err)
                    this.setState({ isPosting: false, [modal]: false, isSubmitPosting: false });
                    this.props?.manageMessageModal('Fail', true);
                })
    }

    handleResetNotify = () => {
        this.setState({ notificationMessage: "", openNotification: false });
    }

    getFormattedDealStepName = () => {
         if(this.props?.submissionDetails?.project_id !=null){
            let formattedName = [];
            let deal_memo_steps = this.props?.submissionDetails?.deal_memo_steps;
            deal_memo_steps?.map(item => {
                let dealStepName = (item?.deal_step_writers ? (item?.deal_step_writers + '; ') : '') + (item?.deal_step_number ? (item?.deal_step_number + '; ') : '') + (item?.deal_step ? item?.deal_step : '-');
                formattedName.push(dealStepName);
            })
            return formattedName.join('\n');
        } else{
            return null;
        }
    }

    formatCompareToNames = (compareTo) => {
        let formattedCompareToList = [];
        compareTo?.map(item => {
            if (item?.compare_to) {
                formattedCompareToList?.push(item?.compare_to + ' - ' + getFormattedDate(item?.compare_to_received_date, false))
            }
        })
        return formattedCompareToList?.join('\n');
    }

    handleProjectRedirection = () => {
        if (this.props?.submissionDetails?.project_id) {
            let url = '/projectDetails/' + this.props?.submissionDetails?.project_id;
            return this.props?.history?.push(url);
        }
    }
    handleSubmissionRedirection = (value) => {
        console.log(value);
        if (value) {
            let url = '/submissionDetails/' + value ;
            return this.props?.history?.push(url);
        }
    }

    render() {
        const { submissionDetails } = this.props;
        return (
            <MDBContainer fluid className="submissionDetailsContainer p-0">
                {
                    this.state.showReviseSubmissionModal &&
                    <MainViewModalComponent
                        open={this.state.showReviseSubmissionModal}
                        handleClose={() => this.setState({ showReviseSubmissionModal: false })}
                        headerText={"Revise Submission"}
                        mode={""}
                        messageMode={"revise_submission"}
                        modalName={"Revise Submission"}
                        submissionId={this.props?.submissionId}
                        executive={submissionDetails?.created_by_name}
                        handlePageRefresh={this.handlePageRefresh}
                        divisionId={this.props?.submissionDetails?.division_id}
                    />
                }
                {this.state.showRecordCannotbeDeleted &&
                    <MessageModal
                        open={this.state.showRecordCannotbeDeleted}
                        title={"Cancel Submission"}
                        message={"Submission Can not be Cancelled"}
                        primaryButtonText={"Ok"}
                        secondaryButtonText={"Close"}
                        onConfirm={(e) => this.setState({ showRecordCannotbeDeleted: false })}
                        handleClose={(e) => this.setState({ showRecordCannotbeDeleted: false })}
                    />
                }
                {this.state.showCancelSubmissionModal &&
                    <MessageModal
                        className="cancelMessageModalButton"
                        open={this.state.showCancelSubmissionModal}
                        title={"Cancel Submission"}
                        message={"Are you sure you would like to cancel this submission? The creating user will be notified of its deletion. This action cannot be undone."}
                        primaryButtonText={"Exit"}
                        secondaryButtonText={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : "Cancel"}
                        onConfirm={this.cancelSubmission}
                        handleClose={(e) => this.setState({ showCancelSubmissionModal: false })}
                    />
                }
                {this.state.showConfirmSubmissionModal &&
                    <MessageModal
                        className="confirm-action-modal"
                        open={this.state.showConfirmSubmissionModal}
                        title={"Confirm Submission"}
                        message={"Are you sure you would like to confirm this submission? The creating user will be notified and the submission will be added to the system. This action cannot be undone."}
                        primaryButtonText={""}
                        primaryConfirmBtnText={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : "Confirm"}
                        secondaryButtonText={"Cancel"}
                        onConfirm={this.handleConfirmSubmission}
                        handleClose={(e) => this.setState({ showConfirmSubmissionModal: false })}
                    />
                }
                {/* <NotificationComponent
                    open={this.state.openNotification}
                    message={this.state.notificationMessage}
                    severity={this.state.severity}
                    duration={3000}
                    handleResetNotify={this.handleResetNotify.bind(this)}
                /> */}
                <MDBCard className="submissionDetailsCard">
                    <MDBCardBody>
                        <MDBCardText>
                            {this.props?.isLoading ? (
                                <div className="ContentLoader">
                                    <CircularProgress />
                                </div>
                            ) : (
                                <div className="submissionDetailsSection">
                                    <div className="sectionWidth">
                                        <MDBRow>
                                            <MDBCol>
                                                <BasicLabel text={"AKA(s)"} />
                                                <p>{this.getCommaSeparatedValues('aka', submissionDetails?.akas) || "-"}</p>
                                            </MDBCol>
                                            <MDBCol>
                                                <BasicLabel text={"Studio Division"} />
                                                <p>{submissionDetails?.division_name || "-"}</p>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol md={6}>
                                                <BasicLabel text={"Submitted To"} />
                                                <p>{this.getSubmittedToNames(submissionDetails?.submitted_to) || "-"}</p>
                                            </MDBCol>
                                            <MDBCol md={6}>
                                                <BasicLabel text={"Submitted By"} />
                                                <p className="submitted-by-details">{this.getSubmittedByNames(submissionDetails?.submitted_by) || "-"}</p>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            {/* <MDBCol md={6}>
                                                    <BasicLabel text={"Company"} />
                                                    <p>{submissionDetails?.company || "-"}</p>
                                                </MDBCol> */}
                                            <MDBCol md={6}>
                                                <BasicLabel text={"Term Deal Company"} />
                                                <p>{(submissionDetails?.is_term_deal_producer && submissionDetails?.term_deal_company) ?
                                                    submissionDetails?.term_deal_company : "-"}
                                                </p>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol md={6}>
                                                <BasicLabel text={"Project"} />
                                                <p className="project-redirection-link" onClick={() => this.handleProjectRedirection()}>{submissionDetails?.project || "-"}</p>
                                            </MDBCol>
                                            <MDBCol md={6} className="deal-step-details">
                                                <BasicLabel text={"Deal Step"} />
                                                <p>{(submissionDetails?.is_none_deal_step === 1 ? 'None' : this.getFormattedDealStepName()) || "-"}</p>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol md={12}>
                                                <BasicLabel text={"Notes"} />
                                                <p className="notes">{submissionDetails?.submission_notes || "-"}</p>
                                            </MDBCol>
                                        </MDBRow>
                                    </div>
                                    <div className="sectionWidth">
                                        <MDBRow>
                                            <MDBCol md={2} className="pr-0">
                                                <BasicLabel text={"Draft Date"} />
                                                <p>{submissionDetails?.draft_date ? getFormattedDate(submissionDetails?.draft_date, false) : "-"}</p>
                                            </MDBCol>
                                            <MDBCol md={2} className="p-0">
                                                <BasicLabel text={"Received Date"} />
                                                <p>{submissionDetails?.received_date ? getFormattedDate(submissionDetails?.received_date, false) : "-"}</p>
                                            </MDBCol>
                                            <MDBCol md={2} className="p-0">
                                                <BasicLabel text={"Coverage Due Date"} />
                                                <p>{submissionDetails?.submission_due_date ? getFormattedDate(submissionDetails?.submission_due_date, false) : "-"}</p>
                                            </MDBCol>
                                            <MDBCol md={2} className="pr-0">
                                                <BasicLabel text={"Draft Version"} />
                                                <p>{submissionDetails?.draft_version || "-"}</p>
                                            </MDBCol>
                                            <MDBCol md={2} className="p-0">
                                                <BasicLabel text={"Page Count"} />
                                                <p>{submissionDetails?.pgs || "-"}</p>
                                            </MDBCol>
                                            <MDBCol md={2} className="p-0">
                                                <BasicLabel text={"WB Page Count"} />
                                                <p>{submissionDetails?.wb_pgs || "-"}</p>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol className='mb-2'>
                                                <BasicLabel text={"Authors"} />
                                                {submissionDetails?.authors?.map((item, index) => (
                                                    <span
                                                        className={`cursorPointer`}
                                                        key={"author" + index} 
                                                        onClick={() => this.props?.handleShowTalentPopup(item?.author_id || item?.talent_group_id, item?.author_id ? 0 : 1)}
                                                    >
                                                        {item?.author}
                                                        {index !== submissionDetails?.authors?.length - 1 && <span>, </span>}
                                                    </span>
                                                ))}
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol md={5}>
                                                <BasicLabel text={"Format"} />
                                                <p>{this.getCommaSeparatedValues('format', submissionDetails?.formats) || "-"}</p>
                                            </MDBCol>
                                            <MDBCol md={2}>
                                                <BasicLabel text={"Box#"} />
                                                <p>{submissionDetails?.box || "-"}</p>
                                            </MDBCol>
                                            <MDBCol md={2}>
                                                <BasicLabel text={"SKU#"} />
                                                <p>{submissionDetails?.sku || "-"}</p>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol md={4}>
                                                <BasicLabel text={"Coverage Type"} />
                                                <p>{submissionDetails?.coverage_type || "-"}</p>
                                            </MDBCol>
                                            <MDBCol md={8}>
                                                <BasicLabel text={"Compare To"} />
                                                {submissionDetails?.compare_to?.length !=0 ? submissionDetails?.compare_to?.map((item, index) => (
                                                    <span
                                                    className={"coveragehyperlink"}
                                                        key={index} 
                                                        onClick={() => this.handleSubmissionRedirection(item.compare_to_id)}
                                                    >
                                                      {item?.compare_to + ' - ' + getFormattedDate(item?.compare_to_received_date, false)+'\n'}
                                                      
                                                      </span>
                                                )) : "-"} 
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol>
                                                <BasicLabel text={"Source Material"} />
                                                {
                                                    submissionDetails?.source_material?.length > 0 ? submissionDetails?.source_material?.map(source => (
                                                        <MDBRow>
                                                            <MDBCol md={4}><p>{`${source?.title || '-'} (${source?.material_type || '-'})`}</p></MDBCol>
                                                            <MDBCol md={5}><p>{`Pub: ${source?.publisher || '-'} (${source?.source_material_date ? getFormattedDate(source?.source_material_date, false) : '-'})`}</p></MDBCol>
                                                            <MDBCol md={3}><p>{`Cop: ${source?.copy_right_date ? source?.copy_right_date : '-'}`}</p></MDBCol>
                                                            {/* <MDBCol md={3}><p>{`Cop: ${source?.copy_right_date ? getFormattedDate(source?.copy_right_date, false) : '-'}`}</p></MDBCol> */}
                                                        </MDBRow>
                                                    )) : <p>{"-"}</p>
                                                }
                                            </MDBCol>
                                        </MDBRow>
                                    </div>
                                </div>
                            )
                            }
                        </MDBCardText>
                    </MDBCardBody>
                </MDBCard>
                <MDBRow>
                    <MDBCol
                        md={`${(submissionDetails?.is_cancel_submission &&
                            !submissionDetails?.is_revise_confirm_submission &&
                            !submissionDetails?.is_submit_submission) ? 10 : 8}`}>
                    </MDBCol>
                    <MDBCol md={`${(submissionDetails?.is_cancel_submission &&
                        !submissionDetails?.is_revise_confirm_submission &&
                        !submissionDetails?.is_submit_submission) ? 2 : 4}`}>
                        <div className={`${!submissionDetails?.is_submit_submission ? "confim-btn-section" : ""} flex actionButtons`}>
                            {submissionDetails?.is_cancel_submission ?
                                <div
                                    className={`${(submissionDetails?.is_cancel_submission &&
                                        !submissionDetails?.is_revise_confirm_submission &&
                                        !submissionDetails?.is_submit_submission) ?
                                        "cancelSubmission ml-5" : "buttonMargin cancelSubmission"}`}
                                >
                                    <BasicButton
                                        variant="outlined"
                                        text="Cancel"
                                        type="inline"
                                        onClick={() => this.setState({ showCancelSubmissionModal: true })}
                                        icon={"check"}
                                    />
                                </div> : ""
                            }
                            {submissionDetails?.is_revise_confirm_submission ?
                                <div className="flex">
                                    <div className="buttonMargin revisionButtonWidth">
                                        <BasicButton
                                            variant="contained"
                                            text="Return for Revision"
                                            type="inline"
                                            onClick={() => this.setState({ showReviseSubmissionModal: true })}
                                            icon={"share"}
                                        />
                                    </div>
                                    <div className="buttonMargin confirmSubmission">
                                        <BasicButton
                                            variant="outlined"
                                            text="Confirm"
                                            type="inline"
                                            onClick={() => this.setState({ showConfirmSubmissionModal: true })}
                                            icon={"check"}
                                        />
                                    </div>
                                </div> : ""
                            }
                            {submissionDetails?.is_submit_submission ?
                                <div className="buttonMargin">
                                    <BasicButton
                                        variant="contained"
                                        text={this.state.isSubmitPosting ? <CircularProgress color="inherit" size={20} /> : "Submit"}
                                        type="inline"
                                        onClick={this.handleSubmitSubmission}
                                        icon={"check"}
                                    />
                                </div> : ""
                            }
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBContainer >
        )
    }
}

export default withUserContext(SubmissionDetails)